<template>
  <div class="sub-header-thin-div">
    <v-card
      :class="isMobile ? 'mx-auto my-10' : 'mx-auto my-20'"
      :max-width="isMobile ? '90%' : '70%'"
    >
      <br />
      <v-card-text :class="isMobile ? 'my-1 text-center' : 'my-10 text-center'">
        <span
          :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`"
          >{{ $t("business_user_agreement") }}</span
        >
      </v-card-text>
      <v-card-text
        class="m-5"
        :style="isMobile ? 'font-size: 12px;' : 'font-size: 20px;'"
      >
        <div>
          <h2 class="text-center font-30"><b>I</b></h2>
          <h2><b>1. Herzlich Willkommen bei Eventbox!</b></h2>
          <p>
            Wir betreiben eine Ticketing- und Registrierungsplattform und unsere
            Funktion, Menschen aus aller Welt durch Events aller Art
            zusammenzubringen. Zudem schaffen wir eine neue Inspiration bei
            Menschen aus aller Welt über die Eventszene. Über unsere Plattform
            und unsere mobile App ermöglichen wir es Menschen rund um den
            Globus, Events zu erstellen, Events zu entdecken, Kontakte zu
            knüpfen, Eventserlebnisse miteinander zu teilen und Tickets zu
            holen.
          </p>

          <h2>
            <b
              >1.2 Eventbox-Services. Hier finden Sie eine Übersicht über unsere
              Services.</b
            >
          </h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              1.2.1. Ticketing-Services: Wir bieten Ihnen und anderen
              Veranstaltern eine Plattform, auf der Sie Tickets verkaufen und
              sich den Umsatz aus Ihrem Event auszahlen lassen zu können.
            </li>
            <li>
              1.2.2. Marketing- und Betriebsservices: Sie können ab der
              Registrierung als Veranstalter die Marketing- und Betriebsservices
              kostenfrei nutzen. Alle Marketing- und Betriebsservices werden
              nach unserem Ermessen erbracht und unterliegen den
              Nutzungsbedingungen, dieser Händlervereinbarung und etwaigen
              Sondervereinbarungen, falls vorhanden.
            </li>
            <li>
              1.2.3. Veranstalter-Services: In dieser Händlervereinbarung
              bezieht sich der Begriff sowohl auf Ticketing als auch auf die
              Marketing- und Betriebsservices. Zur Klarstellung: Wenn in den
              Nutzungsbedingungen der Begriff <b>"Services"</b> verwendet wird,
              so sind neben Services auch die Veranstalter-Services gemeint.
            </li>
          </ul>

          <br />
          <h2><b>2. Unsere Händlervereinbarung</b></h2>
          <h2><b>2.1 Zweck und Geltungsbereich.</b></h2>
          <p>
            In dieser Händlervereinbarung sind die Bedingungen für Ihre Nutzung
            der Veranstalter-Services festgelegt. Die Benutzer unserer
            Veranstalter-Services werden in dieser Händlervereinbarung unter
            anderem mit den Begriffen "Sie", "Ihre", "Ihr" usw. bezeichnet.
            Indem Sie die Veranstalter-Services nutzen oder sich als
            Veranstalter registrieren, erklären Sie sich mit den Bedingungen
            dieser Händlervereinbarung, den Nutzungsbedingungen und unseren
            Datenschutzrichtlinien, jeweils in der aktuellen Fassung,
            einverstanden und schließen einen verbindlichen Vertrag mit uns ab,
            der unsere VeranstalterServices und Ihre Nutzung derselben regelt.
            Wenn Sie den Bedingungen dieser Händlervereinbarung, den
            Nutzungsbedingungen und unseren Datenschutzrichtlinien nicht
            zustimmen, dürfen Sie unsere Veranstalter-Services nicht nutzen und
            sich nicht als Veranstalter registrieren.
          </p>

          <br />
          <h2>
            <b>3. Voraussetzungen zur Nutzung der VeranstalterServices</b>
          </h2>
          <h2><b>3.1 Voraussetzungen.</b></h2>
          <p>
            Um die Veranstalter-Services zu nutzen, müssen Sie: 1. die Befugnis
            haben, diese Händlervereinbarung in Ihrem eigenen Namen oder im
            Namen der juristischen Person, die die Veranstalter-Services nutzen
            möchte, abzuschließen; 2. unsere Nutzungsbedingungen und alle
            geltenden Gesetze einhalten; und 3. die Allgemeinen
            Geschäftsbedingungen und alle anderen erforderlichen Vereinbarungen
            mit Dritten, falls zutreffend, lesen und ihnen zustimmen. Alle von
            Ihnen bereitgestellten Informationen müssen zutreffend, genau und
            vollständig sein und über etwaige Änderungen müssen Sie uns
            unverzüglichinformieren. Wir können jederzeit, aus einem beliebigen
            Grund, mit oder ohne Vorankündigung Ihre Registrierung für die
            Veranstalter-Services gestatten oder verweigern, Ihren Zugriff auf
            die Veranstalter-Services einschränken, aussetzen oder einstellen
            und/oder Transaktionslimits für Auszahlungen festlegen. Auch diese
            Voraussetzungen können von uns jederzeit geändert werden. Nach der
            Registrierung für die Veranstalter-Services fordern wir Sie
            möglicherweise zur Angabe weiterer Informationen zu Ihrer
            natürlichen Person, der juristischen Person, die Sie vertreten
            (falls zutreffend), und den Direktoren/wirtschaftlichen Eigentümern
            der von Ihnen vertretenen juristischen Person an. Zu den
            Registrierungsdaten können beispielsweise aktuelle Adresse,
            Firmenname, Produktbeschreibungen, Website-Adresse, Bankkontodaten
            oder sonstige Zahlungsinformationen, Steueridentifikationsnummer,
            Geburtsdatum, Ausweis- oder Führerscheinnummer, Herkunftsland,
            Kopien von staatlichen Ausweisdokumenten und andere personenbezogene
            Daten gehören. Wir können diese Informationen dafür verwenden, Ihre
            Identität sowie die Gültigkeit und/oder Rechtsmäßigkeit Ihrer
            Transaktionen und/oder Ihre Berechtigung zur Nutzung der
            Veranstalter-Services zu überprüfen. Sie müssen: 1. unverzüglich
            genaue und vollständige Angaben machen und 2. diese Angaben
            regelmäßig und zeitnah aktualisieren, um fortwährend ihre
            Genauigkeit und Vollständigkeit zu gewährleisten.
          </p>

          <h2><b>3.2 Ermächtigung zur Offenlegung.</b></h2>
          <p>
            Wir können Registrierungsdaten und Informationen über Events und die
            Nutzung der Services an unsere Zahlungsabwicklungspartner,
            Bezahlkartensysteme sowie an Ihre Bank oder ein anderes
            Finanzinstitut weitergeben, sofern diese Dritten jeweils involviert
            sind. Darüber hinaus ermächtigen Sie uns, Ihre Registrierungsdaten
            zu verifizieren und von Dritten, darunter Kreditauskunfteien,
            verifizieren zu lassen.
          </p>

          <h2><b>3.3 Auskunftsversäumnis.</b></h2>
          <p>
            Sie sind erst dann berechtigt, sich den Umsatz aus Tickets oder
            Registrierungen von uns auszahlen zu lassen, wenn Sie vollständige
            und genaue Registrierungsdaten bei uns hinterlegt haben. Wir
            behalten uns das Recht vor, Ihr Eventbox-Businesskonto und/oder
            Ihren Zugriff auf die Veranstalter-Services auszusetzen oder
            einzustellen und alle Ihnen ansonsten zustehenden Zahlungen
            einzubehalten, wenn wir der Ansicht sind, dass Ihre
            Registrierungsdaten ungenau oder unvollständig sind.
          </p>

          <h2><b>3.4 Verbotene Events; verbotene Transaktionen.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              3.4.1. Verbotene Events. Sie dürfen auf den Eventbox keine Events
              veröffentlichen oder durch die Veranstalter-Services keine
              Handlungen vornehmen, die:
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; eine Verletzung von anwendbaren örtlichen,
                  bundesstaatlichen, regionalen, nationalen oder anderweitigen
                  Gesetzen, Regelungen oder Verordnungen darstellen oder
                  ermöglichen;
                </li>
                <li>
                  b&rpar; nach den Regeln für Bezahlsysteme verboten sind;
                </li>
                <li>
                  c&rpar; Inhalte enthalten, die gegen die Nutzungsbedingungen
                  oder CommunityRichtlinien von Eventbox verstoßen;
                </li>
              </ul>
            </li>
            <li>
              3.4.2. Verbotene Transaktionen. Folgende Transaktionen dürfen Sie
              nicht abwickeln und Sie sichern zu und gewährleisten, dass Sie
              keine solchen Transaktionen veranlassen werden:
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; betrügerische oder kriminelle Transaktionen; oder
                </li>
                <li>
                  b&rpar; Transaktionen, die keinen Bezug zu Ihren Events auf
                  den EventboxObjekten aufweisen. Zur Klarstellung: Unsere
                  Zahlungsabwicklungsservices dürfen nur für den Verkauf von
                  Tickets oder Registrierungen für Ihre Events auf den
                  Eventbox-Objekten benutzt werden.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Wenn wir feststellen, dass ein Event oder eine Transaktion in eine
            der oben genannten Kategorien fällt, gilt sie als <b>"VERBOTEN"</b>.
          </p>

          <br />
          <h2>
            <b
              >4. Aussetzung und Einstellung der VeranstalterServices;
              Fortbestand der Verpflichtungen.</b
            >
          </h2>
          <h2>
            <b>
              4.1 Aussetzung und Einstellung. Ausfolgenden Gründen können wir
              den Zugriff auf die Veranstalter-Services für Sie aussetzen oder
              einstellen.
            </b>
          </h2>
          <p>
            Wir können die Freigabe für Sie zur Mitwirkung an den
            Eventbox-Services und Objekten als Veranstalter und/oder zum Erhalt
            von Zahlungen jederzeit und aus beliebigem Grund aussetzen oder
            widerrufen, unter anderem in folgenden Fällen:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              a&rpar; wenn Sie die Voraussetzungen zur Nutzung der Services
              nicht mehr erfüllen oder gegen eine für Sie geltende Bestimmung
              dieser Händlervereinbarung, der Nutzungsbedingungen oder einer
              anderen Eventbox-Richtlinie verstoßen;
            </li>
            <li>
              b&rpar; wenn Sie geschuldete Beträge nicht fristgerecht zahlen;
            </li>
            <li>
              c&rpar; wenn sich unsere Zahlungsabwicklungspartner oder
              Bezahlkartensystemanbieter weigern, Zahlungen an Sie zu tätigen
              oder an Services mitzuwirken, an denen Sie beteiligt sind;
            </li>
            <li>
              d&rpar; wenn rechtliche Schritte gegen uns eingeleitet werden, um
              Geldmittel oder sonstiges Vermögen, dessen Eigentümer Sie sind und
              das sich in unserem Besitz befindet, nach deutschem Recht zu
              pfänden ("garnish") oder zu beschlagnahmen ("attach");
            </li>
            <li>
              e&rpar; wenn wir erfahren, dass Sie aus irgendeinem Grund einer
              Partei ein Recht auf Abtretung von Zahlungen eingeräumt haben;
              oder
            </li>
            <li>
              f&rpar; wenn Sie als ein verbotener Händler, verbotene Events
              veranstaltet haben und/oder verbotene Transaktionen abwickeln oder
              veranlassen.
            </li>
          </ul>

          <h2><b>4.2 Beendigung durch den Veranstalter.</b></h2>
          <p>
            Sie können Ihre Mitwirkung als Veranstalter jederzeit beenden, indem
            Sie Ihr Konto nach Maßgabe der Nutzungsbedingungen löschen. Nach der
            Löschung Ihres Kontos sind Sie weiterhin an diese
            Händlervereinbarung und alle anderen für Sie geltenden
            Eventbox-Richtlinien sowie jegliche schriftliche Vereinbarung mit
            uns gebunden.
          </p>

          <h2><b>4.3 Wirkung der Beendigung.</b></h2>
          <p>
            Wenn eine Partei diese Händlervereinbarung kündigt, zahlen wir Ihnen
            eventuell geschuldete Beträge über unsere Zahlungsabwicklungspartner
            innerhalb einer vertretbaren Frist aus, sofern die Auszahlung nach
            anwendbaren Gesetzen, Vorschriften und Anordnungen zulässig ist.
            Gemäß Abschnitt 6.2 "Abzüge, Aufrechnung und Rücklagen" können
            geschuldete Beträge einbehalten, um etwaige Rückbuchungen,
            Rückerstattungen, Gutschriften, Debitorensalden und andere Abzüge
            von den Beträgen zu decken, die wir Ihnen schulden. Ansonsten
            unterliegen wir Ihnen gegenüber keinen weiteren
            Zahlungsverpflichtungen.
          </p>

          <h2><b>4.4 Fortbestand der Verpflichtungen.</b></h2>
          <p>
            Alle Bestimmungen dieser Händlervereinbarung, die ihrer Natur nach
            die Beendigung dieser Händlervereinbarung überdauern sollten,
            bleiben wirksam &lpar;einschließlich Ihrer Verpflichtungen in Bezug
            auf Rückerstattungen, Zahlungen und Rückbuchungen.
          </p>

          <br />
          <h2><b>5. Zahlungsabwicklung.</b></h2>
          <h2><b>5.1. Eventbox-Zahlungsabwicklung.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              5.1.1.Hier finden Sie eine Übersicht über die
              Eventbox-Zahlungsabwicklung. Wenn Sie die
              Eventbox-Zahlungsabwicklung nutzen, agieren wir hinsichtlich der
              Abwicklung von Transaktionen in Ihrem Namen über unsere
              Zahlungsabwicklungsmethode als Zahlungsagent mit beschränkter
              Haftung. Als Zahlungsagent mit beschränkter Haftung verarbeiten
              wir die Gesamteinnahmen aus dem Verkauf von Tickets,
              Registrierungen und sonstigen Artikeln für Ihre Events an
              Verbraucher ("Eventerlöse"). Als Gegenleistung zahlen Sie uns
              neben den anwendbaren Eventbox-Gebühren für jedes Ticket und jede
              Registrierung, die EventboxZahlungsabwicklungsgebühr für die
              Ermöglichung der Transaktion
              ("Eventbox-Zahlungsabwicklungsgebühr").
            </li>
            <li>
              5.1.2. Auszahlungen und Zahlungsfristen.
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; Die Eventerlöse werden Veranstaltern, maximal 7
                  Werktage nach Abschluss des Events ausgezahlt, abzüglich
                  Gebühren und Steuern sowie sonstiger Abschläge und Abzüge
                  durch Aufrechnung (wie unten definiert), sofern zutreffend. Zu
                  diesem Zeitpunkt veranlassen wir die Auszahlung über Ihre
                  bevorzugte Auszahlungsmethode. Wir haften nicht für
                  verspätete, abgelehnte oder ausbleibende Auszahlungen, die auf
                  Zahlungsverzug, Zahlungsausfall oder Vertragsverletzung
                  Ihrerseits zurückzuführen sind.
                </li>
                <li>
                  b&rpar; Falsche oder unzutreffende Informationen. Wir
                  verlassen uns in Bezug auf die Abwicklung von Transaktionen in
                  Ihrem Namen auf die Informationen, die Sie uns bereitstellen.
                  Falls die von Ihnen bei uns oder unseren
                  Zahlungsabwicklungspartnern hinterlegten Konto- oder
                  Karteninformationen fehlerhaft sind, müssen Sie uns für
                  etwaige Verluste oder Kosten, die uns aufgrund der falschen
                  Angaben entstehen, entschädigen und uns davon schadlos halten
                  und freistellen.
                </li>
                <li>
                  c&rpar; Transaktionslimits. Aus Risikomanagement- und
                  Sicherheitsgründen sowie aufgrund der von unseren
                  Zahlungsabwicklungspartner gestellten Anforderungen kann es
                  vorkommen, dass wir nach eigenem Ermessen ein
                  Transaktionslimit für den Betrag einer von Ihnen über die
                  Eventbox abgewickelten Transaktion festlegen, wobei Sie uns
                  ermächtigen, jede über diesem Limit liegende Transaktion
                  abzulehnen.
                </li>
                <li>
                  d&rpar; Währungen. Wir verarbeiten nur Zahlungen in EURO.
                </li>
              </ul>
            </li>
          </ul>

          <h2><b>5.2 Rückerstattungen.</b></h2>
          <p>
            Zur Stornierung von Kreditkartenzahlungen und anderen
            Rückerstattungen kommt es unter anderem, wenn ein Verbraucher eine
            Transaktion bei einem Bezahlkartensystem reklamiert. Als
            Veranstalter sind Sie dafür verantwortlich, uns für alle
            Rückerstattungen in Verbindung mit Ihren Eventerlösen oder anderen
            Zahlungen sowie für alle damit verbundenen Kosten für
            Kreditkartengesellschaften, Zahlungsabwicklung und Wiedervorlage
            sowie Bußgelder und sonstige Gebühren die uns, mit Rückerstattungen
            imZusammenhang mit Ihren Events entstehen, unverzüglich und in
            vollem Umfang zu entschädigen und diese zu erstatten. Als Ihr
            Zahlungsdienst unternehmen wir Anstrengungen in einem geschäftlich
            vertretbaren Umfang, etwaige Rückerstattungen in Ihrem Namen zu
            verwalten und anzufechten. Sie bevollmächtigen uns dazu und sichern
            zu, im Rahmen des Wiedervorlageprozesses für Rückerstattungen mit
            uns zu kooperieren. Wir sind jedoch nicht dazu verpflichtet,
            Rückerstattungen anzufechten oder zu vertreten,
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              a&rpar; wenn unsere Erfolgsaussichten nach unserem eigenen
              Ermessen gering sind;
            </li>
            <li>
              b&rpar; wenn eine Rückerstattung nach Maßgabe der
              Rückerstattungsrichtlinie Ihres Events gerechtfertigt ist; oder
            </li>
            <li>
              c&rpar; wenn eine Rückerstattung nach Maßgabe von Abschnitt 8.2
              "Sonderfälle" gerechtfertigt ist.
            </li>
          </ul>
          <p>
            Die Regeln für Zahlungssysteme (wie nachfolgend definiert) geben den
            Bezahlkartensystemen einen Ermessensspielraum bei der Handhabung von
            Rückerstattungen, und wenn wir einen in Ihrem Namen angestrengten
            Rückerstattungsstreit verlieren, sind Sie auch in diesem Fall
            verpflichtet, uns eine Rückzahlung zu leisten.
          </p>

          <h2><b>5.3 Zahlungssystemregeln.</b></h2>
          <p>
            Gemäß den Regeln für Zahlungssysteme sind Sie unter anderem dazu
            verpflichtet, 1&rpar; nur im guten Glauben getätigte Transaktionen
            abwickeln zu lassen, 2&rpar; Einschränkungen in Bezug auf die
            Nutzung der Logos und Marken der Bezahlkartensysteme zu beachten und
            3&rpar; den Kartenanbieter und seine verbundenen Unternehmen dazu zu
            ermächtigen, Ihren Namen und Ihre Anschrift als Nachweis Ihrer
            Teilnahme am jeweiligen Bezahlkartensystem zu nutzen. Die Regeln für
            Zahlungssysteme sind für Sie öffentlich zugänglich und können sich
            von Zeit zu Zeit ändern. Bei Änderungen an den Regeln für
            Zahlungssysteme müssen wir diese Händlervereinbarung möglicherweise
            aktualisieren, um diese Änderungen aufzunehmen.
          </p>

          <h2>
            <b
              >5.4 Beziehungen. Wenn ein Verbraucher eine Zahlung tätigt, ist
              dies als direkte Zahlung zu bewerten.</b
            >
          </h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              a&rpar; Unsere Aufgaben. Wir bieten Ihnen keine Bank-, Einlagen-,
              Wertaufbewahrungs-, Versicherungs- oder sonstigen
              Finanzdienstleistungen an, mit der Ausnahme, dass wir Ihnen, als
              Zahlungsagent mit beschränkter Haftung (wie oben definiert)
              dienen. Wir können Ihnen zwar einen Saldo der Eventerlöse in Ihrem
              Eventbox-Konto anzeigen, doch das angezeigte Guthaben spiegelt
              lediglich die Eventerlöse wider, die von einem
              Drittanbieter-Zahlungsdienst oder von unseren
              Zahlungsabwicklungspartnern eingezogen wurden. Diese Hinweise
              stellen keine Anzahlung oder sonstige Verpflichtung von Eventbox
              oder eines Zahlungsabwicklungspartners Ihnen gegenüber dar und
              werden nur zu Berichts- und Informationszwecken bereitgestellt.
              Sie haben keinen Anspruch auf das angezeigte Guthaben und verfügen
              über keinerlei Eigentums- oder sonstigen Rechte daran, bis die
              entsprechenden Beträge gemäß dieser Händlervereinbarung an Sie
              ausgezahlt wurden. Sie haben keinen Anspruch auf Zinsen oder
              andere Bezüge im Zusammenhang mit Beträgen, die noch nicht
              ausgezahlt wurden.
            </li>
            <li>
              b&rpar; Unsere Verpflichtungen gegenüber Verbrauchern. Wenn ein
              Verbraucher eine Zahlung tätigt (z. B. beim Kauf eines Tickets für
              ein Event) und diese Zahlung dann von einem unserer
              Zahlungsabwicklungspartner verarbeitetwird, müssen Sie sie genauso
              behandeln, als ob der Verbraucher das Geld direkt an Sie gezahlt
              hätte. Das bedeutet, dass Sie dem Verbraucher alle beworbenen
              Waren und Dienstleistungen auf dieselbe Art und Weise verkaufen
              bzw. bereitstellen müssen, als hätte er die Eventerlöse direkt an
              Sie gezahlt, unabhängig davon, ob Sie die jeweiligen Eventerlöse
              bereits erhalten haben oder jemals erhalten werden.
            </li>
          </ul>

          <p>Als Ihr Zahlungsagent sind wir berechtigt:</p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>1&rpar; Als Ihr Zahlungsagent sind wir berechtigt:</li>
            <li>
              2&rpar; Umbuchungen oder Upgrades für Tickets oder Registrierungen
              für Verbraucher vorzunehmen (sofern Sie Umbuchungen im Rahmen der
              Registrierung für Ihr Event gestatten);
            </li>
            <li>
              3&rpar; in Übereinstimmung mit dieser Händlervereinbarung und
              etwaigen anderen Vereinbarungen mit Zahlungsabwicklern
              Rückerstattungen an Verbraucher zu leisten;
            </li>
            <li>4&rpar; Rückerstattungen zu verwalten und anzufechten.</li>
          </ul>

          <p>
            Wir haften nicht für Handlungen oder Unterlassungen Ihrerseits und
            unsere Zahlungsverpflichtung Ihnen gegenüber steht unter dem
            Vorbehalt Ihrer Einhaltung dieser Händlervereinbarung, der
            Nutzungsbedingungen und der tatsächlichen Zahlung der Eventerlöse
            durch die Verbraucher.
          </p>

          <h2><b>5.5 Bestätigungen.</b></h2>
          <p>
            Wenn eine Bestellung von einem Verbraucher aufgegeben und durch
            Eventbox bestätigt wurde, wird eine Bestätigungsnachricht generiert
            und es wird eine eindeutige und einzigartige Barcode-Nummer
            ausgegeben. Sie verpflichten sich, vorbehaltlos alle Verpflichtungen
            bezüglich Tickets zu akzeptieren, einzulösen und zu erfüllen, die
            über die Services bestätigt wurden. Sie sind dafür verantwortlich,
            die Barcode-Nummer des Verbrauchers und/oder für das Event geltende
            Einschränkungen vor dem jeweiligen Event zu überprüfen.
          </p>

          <br />
          <h2><b>6.Gebühren</b></h2>
          <h2><b>6.1 Gebühren.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              6.1.1. Gebühren und Steuern. Sie sind für alle Gebühren und
              Steuern im Zusammenhang mit Ihrer Nutzung der
              Veranstalter-Services verantwortlich. Sie sind allein
              verantwortlich für die Festlegung der Preise für Tickets und
              Registrierungen für Ihre Events. Sie sind verpflichtet, uns alle
              anfallenden Gebühren für die Veranstalter-Services zu zahlen,
              einschließlich der transaktionsbezogenen Servicegebühren und
              Zahlungsabwicklungsgebühren für die Ticketing-Services und der von
              Ihrem Konto abgebuchten oder in Rechnung gestellten Gebühren für
              die Marketing- und Betriebsservices. Sie sind allein
              verantwortlich für alle Zahlungsabwicklungsgebühren,
              einschließlich EventboxZahlungsabwicklungsgebühren (falls
              zutreffend), Kreditkartengebühren, anfallende Steuern (siehe
              Abschnitt 9 "Steuern") und alle anderen Beträge, die Sie einer
              anderen Partei im Zusammenhang mit den Veranstalter-Services
              schulden. Die Höhe der Gebühren und Steuern variiert je nach
              Rechtsordnung und Währung. Die aktuelle EventboxAbwicklungsgebühr
              betragen zehn Prozent von Ihrem erwirtschafteten Eventerlös.
              Gebühren und Steuern können sich von Zeit zu Zeit ändern;dies
              betrifft dann alle nach der Änderung durchgeführten Transaktionen.
              Obwohl wir Ihnen unter bestimmten Umständen gestatten, im Rahmen
              der Eventerstellung alle oder einen Teil der Gebühren und Steuern
              an die Verbraucher zu übertragen, die sie dann in Ihrem Namen
              zahlen, bleiben Sie letzten Endes für alle Gebühren und Steuern
              verantwortlich, und wir behalten uns das Recht vor, diese Option
              jederzeit ganz oder teilweise zu widerrufen oder zu ändern. Wir
              können Ihnen auch zu üblichen und angemessenen Sätzen 1. Kosten
              für Nachforschungen und/oder Anwaltsgebühren in Rechnung stellen,
              die uns entstehen, wenn wir auf Auskunftsersuchen Dritter oder
              staatlicher Behörden, Pfändungen oder Beschlagnahmungen Ihres
              Kontos reagieren müssen, ebenso wie 2. Nachforschungen und
              Aktivitäten, die zur Verifizierung und Aktualisierung des
              Zahlungsempfängers erforderlich sind.
            </li>
            <li>
              6.1.2. Verbrauchergebühren. Möglicherweise stellen wir den
              Verbrauchern zusätzliche Gebühren in Rechnung. Wir können
              Verbrauchern neben den Eventbox-Gebühren diverse Sondergebühren in
              Rechnung stellen, die nicht an Sie übertragbar sind und die mit
              dem Verkauf von Tickets und Registrierungen, der Bearbeitung,
              Verarbeitung und/oder dem Zugriff auf verschiedene
              Eventbox-Inhalte und -Services zusammenhängen. Falls wir
              Verbrauchergebühren erheben, legen wir deren Höhe sowie die dafür
              geltenden Rückerstattungsrichtlinien fest. Wir sind nicht
              verpflichtet, die Verbrauchergebühren Ihnen gegenüber
              offenzulegen, und können jederzeit Änderungen daran vornehmen.
              Unbeschadet anderslautender Vereinbarungen stimmen Sie zu, keine
              Zahlungsform oder -methode gegenüber einer anderen zu bevorzugen
              und Verbrauchern keine zusätzlichen Gebühren oder sonstigen
              Beträge im Zusammenhang mit einer Zahlungsmethode zu berechnen,
              wenn Sie diese Gebühren oder sonstigen Beträge nicht für alle
              Zahlungsmethoden berechnen.
            </li>
            <li>
              6.1.3. Verzug und Unterlassung. Wir bemühen uns, Sie rechtzeitig
              über fällige Beträge zu informieren, aber wenn wir dies nicht tun,
              entbindet Sie dies nicht von Ihren Zahlungsverpflichtungen. Fehlt
              ein Posten über einen Betrag, den Sie uns oder einem Dritten
              schulden, auf einer Abrechnung oder Rechnung, so stellt dies
              keinen Verzicht auf das Recht auf die entsprechende Zahlung dar.
              Ihre Zahlungsverpflichtung in Bezug auf den fehlenden Posten
              bleibt bestehen; dieser wird möglicherweise in einer späteren
              Abrechnung oder Rechnung ausgewiesen.
            </li>
          </ul>

          <h2><b>6.2. Abzüge, Aufrechnung und Rücklagen.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              6.2.1. Abzüge und Aufrechnung. Wir können Beträge, die Sie
              schulden, mit Ihrer Auszahlung verrechnen oder einbehalten.
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; Unbeschadet der vorstehenden Bestimmungen aus
                  Abschnitt 6.1 ziehen wir folgende Beträge von Ihren
                  Eventerlösen ab:
                  <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                    <li>1&rpar; Eventbox-Gebühren;</li>
                    <li>
                      2&rpar; Rechnungs- und Zahlungskosten sowie sämtliche
                      Kosten im Zusammenhang mit Transaktionen einschließlich
                      Abwicklungsgebühren und Bankgebühren;
                    </li>
                    <li>3&rpar; alle anfallenden Steuern; und</li>
                    <li>
                      4&rpar; Beträge zur Deckung von Rückgaben,
                      Rückerstattungen, Rückbuchungskosten, Zinsen und
                      Gutschriften.
                    </li>
                  </ul>
                </li>
              </ul>
              Sie haben Anspruch auf Auszahlung Ihrer Erlöse nach Abzug der
              vorgenannten Beträge. Darüber hinaus sind wir berechtigt, etwaige
              weitere Abzüge in Übereinstimmung mit dieser Händlervereinbarung
              vorzunehmen und Ihren Auszahlungsbetrag mit Schulden, Gebühren
              oder anderen Beträgen zu verrechnen, die Sie uns gemäß dieser
              Händlervereinbarung schulden, einschließlich Rückerstattungen,
              Eventbox-Gebühren für Marketing- und Betriebsservices,
              Nachforschungsgebühren, Sicherheitsbeträge und Kundenbeschwerden.
              Wir haften Ihnen gegenüber nicht für Forderungen, die auf unseren
              Beschluss zurückzuführen sind, sonstige Abzüge und Aufrechnungen
              vorzunehmen.
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  1&rpar; Zur Klarstellung: Wir können unser Aufrechnungsrecht
                  unabhängig vom geschuldeten Betrag ausüben. Wenn wir durch
                  Ausübung unseres Aufrechnungsrechts den Betrag, den Sie oder
                  eines Ihrer verbundenen Unternehmen uns im Rahmen dieser
                  Händlervereinbarung schulden, nicht gänzlich decken können,
                  dann gilt dieser Betrag als fällig und geschuldet, bis Sie ihn
                  vollständig beglichen haben; in diesem Fall können wir den
                  Betrag gemäß Abschnitt 11 "Nicht ausschließliche Rechtsmittel
                  und Inkasso" beitreiben.
                </li>
                <li>
                  2&rpar; Überdies sind wir berechtigt, einen beliebigen Teil
                  der Zahlungen im Zusammenhang mit den Eventbox-Service, die an
                  Sie geleistet werden, die Sie schulden oder die Sie
                  anderweitig betreffen, zu sperren, zurückzuweisen,
                  einzufrieren oder an die zuständigen Strafverfolgungsbehörden
                  zu überweisen, sofern dies nach den geltenden Gesetzen und
                  Vorschriften zulässig und/oder erforderlich ist.
                </li>
              </ul>
            </li>
            <li>
              6.2.2. Rücklagen. Wir behalten uns das Recht vor, die gesamten
              Eventerlöse oder einen prozentualen Anteil der Eventerlöse (wobei
              dieser prozentuale Anteil von uns bestimmt wird) sowie andere
              Gebühren für Services, die wir Ihnen bereitstellen, zur Bildung
              von Rücklagen einzubehalten, und zwar:
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  1&rpar; jederzeit, wenn wir dies für notwendig erachten,
                  basierend auf dem Umfang (bzw. dem erwarteten Umfang) von
                  Rückerstattungen, strittigen Zahlungen, Rückbuchungen,
                  Kundenbeschwerden, Betrugsvorwürfen, Änderungen an Ihrem
                  Kreditprofil oder am Risikoprofil des/der zugrunde liegenden
                  Events; und
                </li>
                <li>
                  2&rpar; wenn dies anderweitig notwendig ist, um die Erfüllung
                  Ihrer Verpflichtungen unter dieser Händlervereinbarung zu
                  gewährleisten oder uns vor illegalen, betrügerischen oder
                  missbräuchlichen Handlungen zu schützen.
                </li>
              </ul>
            </li>
            <li>
              6.2.3. Unser Recht zur Bildung von Rücklagen ist nach Abschluss
              des jeweiligen Events weiterhin wirksam, bis entweder:
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; Sie sämtliche Verpflichtungen unter der
                  Händlervereinbarung erfüllt haben und die Fristen für
                  Rückerstattungen, strittige Zahlungen, Rückbuchungen und
                  Beschwerden verstrichen sind; oder
                </li>
                <li>
                  b&rpar; Sie (nach eigenem Ermessen) eine angemessene
                  Besicherung Ihrer Verpflichtungen unter der
                  Händlervereinbarung und (falls zutreffend) der Vereinbarung
                  mit verbundenen Unternehmen gewährt haben, unabhängig davon,
                  ob die strittigen Beträge fällig oder nicht fällig, bedingt
                  oder nicht bedingt bzw. beziffert oder nicht beziffert sind.
                </li>
                <li>
                  c&rpar; Sie (nach eigenem Ermessen) eine angemessene
                  Besicherung Ihrer Verpflichtungen unter der
                  Händlervereinbarung und (falls zutreffend) der Vereinbarung
                  mit verbundenen Unternehmen gewährt haben, unabhängig davon,
                  ob die strittigen Beträge fällig oder nicht fällig, bedingt
                  oder nicht bedingt bzw. beziffert oder nicht beziffert sind.
                </li>
              </ul>
            </li>
          </ul>

          <br />
          <h2><b>7. Auszahlung vor dem Event.</b></h2>
          <h2><b>7.1 Vorabauszahlungen.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              7.1.1. Genehmigung für Vorabauszahlungen. Bis zum Abschluss des
              entsprechenden Events haben Sie keinen Anspruch auf
              IhreEventerlöse. Wir können Ihnen einen anteiligen Vorschuss auf
              die Eventerlöse genehmigen. Abgesehen von Vorabauszahlungen zahlen
              wir die Eventerlöse für ein bestimmtes Event grundsätzlich erst
              nach Abschluss dieses Events aus.
            </li>
            <li>
              7.1.2. Bedingungen für Vorabauszahlungen. Vorabauszahlungen sind
              lediglich Vorschüsse auf Beträge, die Ihnen zu einem späteren
              Zeitpunkt zustehen. Wenn wir Ihnen einen Vorschuss in Form
              Vorabauszahlungen gewähren, können wir die maximale Gesamthöhe der
              gestaffelten Auszahlungen begrenzen und einen bestimmten Anteil
              der Eventerlöse zur Bildung von Rücklagen einbehalten. Wir können
              jederzeit (i) Ihr Recht auf Vorabauszahlungen widerrufen oder
              aussetzen und/oder (ii) die Bedingungen (d. h. Häufigkeit,
              Sicherheitsbetrag zur Bildung von Rücklagen und maximale
              Gesamthöhe) für Vorabauszahlungen ändern. Wir behalten uns das
              Recht vor, die Obergrenze für die maximale Gesamthöhe der
              Auszahlungen in Euro auszuweisen.
            </li>
            <li>
              7.1.3. Rückforderung Vorabauszahlungen durch Eventbox. Wir sind
              berechtigt, jegliche Beträge aus Vorabauszahlungen aus einem
              beliebigen Grund vor Abschluss Ihres Events und vor Begleichung
              aller Risiken durch Rückerstattungen zurückzufordern. Sobald Sie
              einen solchen Rückforderungsbescheid erhalten, müsse Sie der
              Forderung unverzüglich Folge leisten. Wir sind berechtigt, unsere
              Zahlungsabwicklungspartner zur Einbehaltung von Geldern gemäß
              Abschnitt 6.2 "Abzüge, Aufrechnung und Rücklagen" und Abschnitt 11
              "Nicht ausschließliche Rechtsmittel und Inkasso" zu veranlassen.
              Zudem nehmen Sie Ihre Verpflichtungen gemäß Abschnitt 5.2
              "Rückerstattung" an, einschließlich Ihrer Verpflichtungen, uns für
              Rückerstattungen und Rückbuchungskosten zu entschädigen.
            </li>
          </ul>

          <h2><b>7.2 Stornierung; Nichterfüllung.</b></h2>
          <p>
            Wir und unsere Zahlungsabwicklungspartner sind nicht verpflichtet,
            Zahlungen an Sie für Events zu leisten, die abgesagt wurden oder bei
            denen das Risiko besteht, dass sie abgesagt werden ("abgesagtes
            Event" nach Definition von Abschnitt 8.2 "Sonderfälle"). Wenn wir
            uns entscheiden, Ihnen eine vollständige oder teilweise Zahlung für
            ein abgesagtes Event zu gewähren, sind Sie in vollem Umfang für
            Rückerstattungsanforderungen und Rückbuchungskosten verantwortlich.
          </p>

          <br />
          <h2><b>8. Rückerstattungen.</b></h2>
          <h2>
            <b
              >8.1 Umgang mit Rückerstattungen, Streitigkeiten und ungültige
              Tickets.</b
            >
          </h2>
          <p>
            Alle Verkäufe werden von Ihnen getätigt und die Verbraucher sind
            Ihre Kunden. Sie sind dafür verantwortlich, Rückerstattungen zu
            zahlen und zu verwalten. Unabhängig von der gewählten
            Zahlungsmethode sind Streitigkeiten über Rückerstattungen
            ausschließlich zwischen Ihnen und den betroffenen Verbrauchern zu
            klären. Wir können im Streitfall einen Schlichtungsversuch
            unternehmen, aber letztendlich ist es Ihre Aufgabe, den Streit
            beizulegen. Bei Sonderfällen sind wir berechtigt, Rückerstattungen
            in Ihrem Namen vorzunehmen. Außerdem sind Sie allein dafür
            verantwortlich, dass Ihre Events ordnungsgemäß gebucht werden und
            dass nur gültige Tickets akzeptiert werden.
          </p>

          <h2><b>8.2 Sonderfälle.</b></h2>
          <p>
            Auch wenn Sie in Ihrer Rückerstattungsrichtlinie festlegen, dass
            Rückerstattungen grundsätzlich nicht erlaubt sind, müssen Sie in
            manchen Fällen Rückerstattungenin Übereinstimmung mit unseren
            Anforderungen an VeranstalterRückerstattungsrichtlinien leisten. Als
            Zahlungsagent mit beschränkter Haftung sind wir berechtigt,
            Rückerstattungen in Sonderfällen in Ihrem Namen vorzunehmen. Wir
            können das Guthaben auf Ihrem Konto aus verbundenen oder nicht
            verbundenen Events oder anderer Quelle verwenden, um
            Rückerstattungen dieser Art zu leisten.
          </p>

          <h2><b>8.3 Entschädigung.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              8.3.1. Sie sind verpflichtet, uns nach entsprechender Aufforderung
              unverzüglich und in vollem Umfang für Rückerstattungen,
              einschließlich unter "Sonderfälle" aufgeführter Rückerstattungen,
              die wir Ihren Verbrauchern gewähren, zu entschädigen, es sei denn,
              die Ursache für die betreffenden Rückerstattungen ist
              Fahrlässigkeit oder vorsätzliches Fehlverhalten unsererseits. Wenn
              Sie Vorabauszahlungen für ein Event erhalten haben, auf das die
              "Sonderfälle" für Rückerstattungen zutreffen, müssen Sie die
              erhaltenen Vorschüsse unverzüglich an eine von uns festgelegte
              Zahlungsadresse überweisen, damit wir die Rückerstattungen leisten
              können.
            </li>
            <li>
              8.3.2. Durch Rückbuchungen entstehen uns Verluste, die über den
              Betrag der zugrundeliegenden Transaktion hinausgehen. Indem wir
              Rückbuchungen durch Rückerstattungen zuvorkommen, grenzen wir
              unsere damit verbundenen Verluste und/oder unsere Schäden durch
              Ihre Verletzung der Bestimmungen dieser Händlervereinbarung ein;
              auch sind Sie dessen ungeachtet verpflichtet, uns für
              Rückerstattungen dieser Art zu entschädigen.
            </li>
            <li>
              8.3.3. Wenn der von Ihnen überwiesene Betrag nicht ausreicht, um
              die unter "Sonderfälle" aufgeführten Rückerstattungen zu decken,
              gilt die Differenz so lange als fällig und zahlbar, bis Sie sie
              vollständig beglichen haben.
            </li>
          </ul>

          <h2><b>8.4 Eventbox-Gebühren.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              8.4.1. Wir können die Rückerstattung von Eventbox-Gebühren
              ablehnen. Wenn wir die Rückerstattung der Eventbox-Gebühren an die
              betroffenen Verbraucher ablehnen, können wir Ihnen die Möglichkeit
              geben, diesen Teil der Rückerstattung selbst zu übernehmen.
            </li>
            <li>
              8.4.2. In bestimmten Fällen können wir beschließen, alle oder
              einen Teil der Eventbox-Gebühren als Teil einer
              Rückzahlungstransaktion zu erstatten. In diesem Fall sind wir
              berechtigt, von Ihnen eine Entschädigung für die erstatteten
              Eventbox-Gebühren zu verlangen.
            </li>
            <li>
              8.4.3. Unsere Richtlinien zur Rückerstattung von Eventbox-Gebühren
              können sich im Laufe der Zeit weiterentwickeln.
            </li>
          </ul>

          <h2><b>8.5 Prozess für Rückerstattungen.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              8.5.1. Rückerstattungen unterliegen Ihrer Verantwortung. Auch wenn
              bestimmte Funktionen unserer Services Ihnen die Möglichkeit
              bieten, Rückerstattungen über die Eventbox-Objekte abzuwickeln,
              tragen Sie die alleinige Verantwortung für alle Rückerstattungen
              an Verbraucher im Zusammenhang mit Ihrem Event.
            </li>
            <li>
              8.5.2. Veranlassung von Rückerstattungen. Rückerstattungen müssen
              über Eventbox abgewickelt werden.
            </li>
            <li>
              8.5.3. Zahlungsabwicklungsfristen für Rückerstattungen; manuelle
              Rückerstattungen. Rückerstattungen an Verbraucher innerhalb einer
              bestimmten Frist nach Maßgabe unserer Zahlungsabwicklungspartner
              direkt über die Services veranlassen.
            </li>
            <li>
              8.5.4. Unsere Fristen für Rückerstattungen. Wir behalten uns vor,
              eine Frist für Rückerstattungsanforderungen aus bestimmten Gründen
              festzulegen. Nach Ablauf dieser Frist können wir die Abwicklung
              von Rückerstattungsanforderungen ablehnen. Wir können Ihnen
              erlauben, Rückerstattungen für ein abgesagtes Event oder einen
              anderen Sonderfall abzulehnen, wenn die entsprechenden
              Anforderungen nach Ablauf dieser Frist eingehen; Sie sind jedoch
              auch in diesem Fall für alle Rückbuchungskosten und die Beilegung
              von Streitfällen im Zusammenhang mit Rückerstattungen
              verantwortlich.
            </li>
            <li>
              8.5.5. Keine Rückerstattungen außerhalb von Eventbox. Sofern von
              uns nicht anders angewiesen, werden außerhalb des Eventbox-Web und
              App keine Rückerstattungen vorgenommen.
            </li>
            <li>
              8.5.6. Benachrichtigung der Verbraucher. Sie müssen die
              Verbraucher schnellstmöglich und vor dem ursprünglichen Beginn des
              Events über dessen Absage oder Nichtstattfinden informieren.
            </li>
            <li>
              8.5.7. Ansprechpartner für Verbraucher. Sie sind der
              Hauptansprechpartner für Verbraucher in Bezug auf
              Rückerstattungsanforderungen und weisen die Verbraucher an, keine
              entsprechenden Anfragen an uns zu richten.
            </li>
            <li>
              8.5.8. Mehrtägige Events. Wenn ein von Ihnen angebotenes
              mehrtägiges Event nur teilweise stattfindet, müssen Sie den
              Kaufpreis für Mehrtagestickets entsprechend anteilig erstatten.
              Hier ein Beispiel: Wenn Sie Tickets für 150 Euro für ein
              dreitägiges Festival verkaufen, von dem ein Tag entfällt, müssen
              Sie für den entfallenen Tag eine Rückerstattung in Höhe von 50
              Euro leisten.
            </li>
            <li>
              8.5.9. Alternative Kompensationen. Wenn Sie Verbrauchern eine
              alternative Kompensation anstelle einer Rückerstattung des
              Kaufpreises anbieten, durch die Sie Ihre Verpflichtungen gegenüber
              den Verbrauchern erfüllen, und/oder wenn es nicht möglich ist, den
              Kaufpreis zu erstatten, dann 1. muss diese alternative
              Kompensation den Wert des Tickets für das Event decken oder
              übersteigen und 2. müssen dieser Wert und alle im Zusammenhang mit
              der alternativen Kompensation zutreffenden Bedingungen (unter
              anderem der Gültigkeitszeitraum, falls eine solche Einschränkung
              gemäß geltenden Gesetzen zulässig ist) klar an die Verbraucher
              kommuniziert werden. Sie tragen die alleinige Verantwortung für
              die Ausstellung von alternativen Kompensationen. Sie sind allein
              dafür verantwortlich, alle anwendbaren örtlichen,
              bundesstaatlichen, regionalen, nationalen oder anderweitigen
              Gesetze, Regelungen oder Verordnungen einzuhalten. Sie haften auch
              dann in vollem Umfang für Rückbuchungskosten, wenn eine
              Gutschrift, Kompensation oder sonstige Ausgleichsleistung gewährt
              wurde; dies gilt sogar dann, wenn keine Kaufpreisrückerstattung
              möglich war. Sollten Sie die Leistungen im Rahmen der alternativen
              Kompensation aus irgendeinem Grund nicht erbringen, etwa weil Sie
              das/die entsprechende/n Event/s nicht wie angekündigt abhalten,
              können wir alle Rechte aus dieser Händlervereinbarung geltend
              machen, einschließlich des Rechts, Rückerstattungen an betroffene
              Verbraucher zu leisten (soweit wir dazu in der Lage sind) und
              diese Beträge direkt von Ihnen einzuziehen.
            </li>
          </ul>

          <br />
          <h2><b>9. Steuern.</b></h2>
          <p>
            In den meisten Fällen sind Sie dafür verantwortlich, alle Steuern zu
            erheben und an die zuständigen Regierungsbehörden zu entrichten. Wir
            können Informationen anfordern und Steuern unter bestimmten
            Umständen einbehalten.
          </p>

          <h2><b>9.1 Ihre Steuerverpflichtungen.</b></h2>
          <p>
            Es liegt in Ihrer alleinigen Verantwortung, zu ermitteln, welche
            Umsatz-, Vergnügungs-, Mehrwert-, Verbrauchs- und anderen Steuern,
            Zölle, Abgaben und Gebühren für die Nutzung der
            Veranstalter-Services und über die VeranstalterServices generierten
            Umsätze anfallen. Sie sind allein dafür verantwortlich, den
            entsprechenden Steuerbetrag einzuziehen und an die zuständige
            Regierungsbehörde ("Steuerbehörde") zu entrichten. Wir übernehmen
            keine Gewähr oder Garantie dafür, dass die Steuerhilfsmittel oder
            -kalkulatoren ("Steuerhilfsmittel"), die wir Ihnen zur Verfügung
            stellen, alle steuerlichen Anforderungen erfüllen, die ggf. für Sie
            gelten, oder dass durch Nutzung der Steuerhilfsmittel der Einzug und
            die Entrichtung aller für Sie geltenden Steuern an die zuständige
            Steuerbehörde sichergestellt werden können. Steuern können je nach
            Art Ihres Events, Ihres Steuerstatus (Privatperson, juristische
            Person, Unternehmen, Verbraucher usw.), Ihres Standorts, des
            Standorts Ihrer Verbraucher, etwaiger für Sie geltender Gutschriften
            und Abzüge sowie anderer Faktoren variieren. Sie entbinden Eventbox
            von jedweder Haftbarkeit in Hinsicht auf Ihre Nutzung der
            Steuerhilfsmittel. Diese sind nicht als juristische oder
            steuerrechtliche Beratung auszulegen. Wenn Sie Steuern einziehen und
            die Eventbox-Zahlungsabwicklung nutzen, zahlen wir diese Beträge
            vorbehaltlich zusammen mit den entsprechenden Eventerlösen an Sie
            aus. Die Entrichtung dieses Betrags an die zuständige Steuerbehörde
            liegt in Ihrer Verantwortung. Sofern Sie Steuerhilfsmittel
            verwenden, bei denen Sie zur Angabe einer
            Steueridentifikationsnummer aufgefordert werden, gewährleisten Sie
            und sichern zu, dass Ihre Angabe wahrheitsgemäß und korrekt ist. Wir
            können Sie weder juristisch noch steuerrechtlich beraten; wir
            empfehlen daher in Bezug auf die Ermittlung der für Sie geltenden
            Rechtssituation und/oder Steuern die Konsultation eines
            Steuerberaters. Wenn wir von einer Steuerbehörde aufgefordert
            werden, Steuern zu zahlen, die auf Ihre Nutzung der
            VeranstalterServices oder Ihre Umsätze aufgrund der Nutzung der
            Veranstalter-Services zurückzuführen sind, müssen Sie uns im vollen
            Umfang für diese Steuern sowie alle damit verbundenen Kosten,
            Geldbußen, Zinsen und Ausgaben entschädigen und diese erstatten.
          </p>

          <h2><b>9.2 Informationsanfragen.</b></h2>
          <p>
            Unbeschadet der Bestimmungen in Abschnitt 9.1 sind wir in bestimmten
            Ländern möglicherweise dazu verpflichtet, Steuern im Zusammenhang
            mit Ihren Umsätzen aus Tickets, Registrierungen oder anderen
            Artikeln über die Veranstalter-Services einzuziehen und an die
            zuständige Steuerbehörde zu entrichten. Um festzustellen, ob wir
            Steuern für Sie einziehen müssen, fordern wir im Rahmen der
            Erstellung eines Events über die Veranstalter-Services unter
            Umständen bestimmte Informationen an. Bei diesen Informationen kann
            es sich um Angaben zu Ihrer Steuerpflicht, zur Art des Events
            und/oder ähnliche Informationen handeln. Für den Fall, dass wir
            diese Informationen anfordern, erklären und versichern Sie, dass
            Ihre Angaben wahrheitsgetreu und fehlerfrei sind. Wir können Sie
            nicht rechtlich oder steuerrechtlich beraten; bitte konsultieren Sie
            bei Fragen zu den auf den Eventbox-Objekten anzugebenden
            Informationen Ihren Rechts- und/oder Steuerberater. Falls eine
            Steuerbehörde von uns verlangt, Steuern für Ihr Event zuzahlen, die
            sich daraus ergeben, dass die von Ihnen angegebenen Informationen
            fehlerhaft sind, müssen Sie uns für diese Steuern sowie alle damit
            verbundenen Kosten, Geldbußen, Zinsen und Ausgaben entschädigen und
            diese erstatten.
          </p>

          <h2><b>9.3 Umsatzsteuer</b></h2>
          <p>
            Gemäß den Umsatzsteuervorschriften der Europäischen Union ("EU")
            sind wir verpflichtet, 1. die Umsatzsteuer auf die
            Eventbox-Servicegebühren für kostenpflichtige Tickets und
            Registrierungen zu erheben, die über die Website und App von
            Veranstaltern mit Sitz in der EU verkauft werden, die keine
            Umsatzsteuer-Identifikationsnummer ("USt-IdNr.") bei uns
            hinterlegen, und 2. die erhobene USt an die zuständige Steuerbehörde
            abzuführen. Wenn Sie Ihre Steuernummer/USt-IdNr bei Eventbox
            hinterlegen, erheben wir keine Umsatzsteuer auf die
            Event-Servicegebühren. Es liegt in Ihrer alleinigen Verantwortung
            sicherzustellen, dass die Steuernummer/USt-IdNr., die Sie bei uns
            hinterlegen (sofern zutreffend), richtig und vollständig ist und im
            richtigen Format vorliegt. Bitte beachten Sie, dass eine Fälschung
            oder Falschdarstellung von Informationen zur Umgehung Ihrer
            umsatzsteuerlichen Pflichten Geldstrafen und/oder strafrechtliche
            Verfolgung nach sich ziehen kann. Sie müssen uns unverzüglich und in
            vollem Umfang für alle Auslagen und Ausgaben entschädigen, die sich
            aus einer etwaigen Fälschung oder Falschdarstellung Ihrer
            Steuernummer/USt-IdNr. auf der Website und App ergeben. Wir
            verlangen nicht, dass Sie über eine Steuernummer/USt-IdNr. verfügen.
            Wenn Sie jedoch in der EU ansässig sind und über keine
            Steuernummer/USt-IdNr. verfügen oder diese nicht angeben, erheben
            wir die Umsatzsteuer auf die Eventbox-Servicegebühren in Ihrem Namen
            und führen sie an die zuständige Behörde ab.
          </p>
          <p>
            <b>Zur Klarstellung:</b> Wir erheben keine Umsatzsteuer auf die
            EventboxZahlungsabwicklungsgebühr oder den Preis von Tickets,
            Registrierungen und anderen Artikeln, die Sie über die Eventbox
            Website und App verkaufen.
          </p>

          <h2><b>9.4 Recht zum Einbehalten von Steuern.</b></h2>
          <p>
            Wir behalten uns das Recht vor, Beträge, die wir Ihnen schulden,
            nach eigenem Ermessen einzubehalten und gemäß anwendbaren örtlichen,
            bundesstaatlichen, regionalen, nationalen oder anderweitigen
            Gesetzen, Regelungen, Verordnungen, Urteilen oder Anordnungen an die
            zuständige Steuerbehörde zu entrichten oder zu einem späteren
            Zeitpunkt die Zahlung weiterer Beträge an nicht erhobenen und nicht
            abgeführten Steuern in Bezug auf Ihre Events von Ihnen zu verlangen.
          </p>

          <br />
          <h2><b>10. Zusicherungen und Gewährleistungen.</b></h2>
          <h2><b>10.1 Vollmacht.</b></h2>
          <p>
            Zusätzlich zu den Zusicherungen und Gewährleistungen im Rahmen
            dieser Händlervereinbarung sichern Sie Folgendes zu und
            gewährleisten in Ihrem Namen und ggf. im Namen der juristischen
            Person, die Sie vertreten:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              a&rpar; dass die juristische Person, die Sie vertreten,
              ordnungsgemäß organisiert, wirksam bestehend und in
              Übereinstimmung mit allen anwendbaren Gesetzen geführt wird;
            </li>
            <li>
              b&rpar; dass Sie über die erforderliche Vollmacht und Befugnis
              verfügen, diese Händlervereinbarung einzugehen und Ihre
              Verpflichtungen in vollem Umfang zu erfüllen;
            </li>
            <li>
              c&rpar; dass Ihr Abschluss dieser Vereinbarung und die Erfüllung
              Ihrer Verpflichtungen hierunter keine Verletzung von Gesetzen,
              Regelungen, Verordnungen, Urteilen, Anordnungen oder sonstigen
              Vereinbarungen darstellt, die für Sie gelten; und
            </li>
            <li>
              d&rpar; dass Sie über die rechtliche Vollmacht und
              Vertretungsbefugnis verfügen, die juristische Person, die Sie
              vertreten, an die Bestimmungen dieser Händlervereinbarung zu
              binden.
            </li>
          </ul>

          <br />
          <h2>
            <b
              >11. Nicht ausschließbare Rechtsmittel; Inkasso und
              Beitreibungskosten</b
            >
          </h2>
          <h2><b>11.1. Nicht ausschließbare Rechtsmittel.</b></h2>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              11.1.1. Verzugszinsen. Für überfällige Beträge, die Sie uns nach
              Maßgabe dieser Händlervereinbarung oder einer Vereinbarung
              schulden, fallen ab dem Fälligkeitsdatum bis zur vollständigen
              Zahlung Verzugszinsen in folgender Höhe an, je nachdem, welcher
              Satz niedriger ist:
              <ul :class="isMobile ? 'ml-5' : 'ml-10'">
                <li>
                  a&rpar; ein Prozent (1 %) pro Monat, monatlich aufgezinst;
                  oder
                </li>
                <li>b&rpar; der gesetzlich zulässige Höchstsatz.</li>
              </ul>
            </li>
            <li>
              11.1.2. Aufrechnung und Rechnungsstellung. Falls Sie uns unter
              dieser Händlervereinbarung Beträge schulden, können wir, soweit
              gesetzlich zulässig:
            </li>
            <li>
              11.1.3. Beträge, die wir Ihnen andernfalls auszahlen würden,
              einbehalten oder aufrechnen (wie in Abschnitt 6.2 "Abzüge,
              Aufrechnung und Rücklagen" beschrieben); und/oder
            </li>
            <li>
              11.1.4. Ihnen eine Rechnung über die fälligen Beträge ausstellen,
              falls der aktuelle Saldo nicht ausreicht, um die Kosten durch
              Aufrechnung zu decken; Rechnungen dieser Art sind innerhalb von
              dreißig (30) Tagen ab Rechnungsdatum von Ihnen zu begleichen.
            </li>
            <li>
              11.1.5. Nicht ausschließbare Rechtsmittel. Jegliche Form von
              Versäumnis oder Verzug unsererseits bei der Durchsetzung oder
              Ausübung eines Rechts, eines Rechtsmittels oder einer Bestimmung
              dieser Händlervereinbarung ist nicht als Verzicht auf diese Rechte
              auszulegen.
            </li>
          </ul>

          <h2><b>11.2 Inkasso</b></h2>
          <p>
            Wir sind berechtigt, Beträge bei Zahlungsverzug oder -säumnis
            einzuziehen, falls innerhalb von dreißig Tagen ab Rechnungsdatum
            keine Zahlung erfolgt. Wir sind berechtigt, Ihnen
            Inkassomitteilungen zu senden; die Zusendung einer solchen
            Mitteilung ist keine Voraussetzung für die Einleitung rechtlicher
            oder sonstiger Schritte zur Beitreibung überfälliger Beträge.
            Außerdem müssen Sie uns unverzüglich für sämtliche Auslagen
            (einschließlich vertretbarer Anwaltshonorare und -kosten)
            entschädigen, die uns durch die Beitreibung überfälliger oder
            sonstiger Beträge entstehen, die Sie oder ein verbundenes
            Unternehmen uns gemäß dieser Händlervereinbarung oder einer
            Vereinbarung mit verbundenen Unternehmen schulden. Sie und Ihre
            verbundenen Unternehmen erklären sich damit einverstanden, dass wir,
            falls wir überfällige Beträge und damit verbundene Gebühren und
            Kosten beitreiben müssen, berechtigt sind, diese Beträge durch
            gerichtliche Verfahren einzuklagen.
          </p>

          <h2><b>11.3 Gerichts- und Erfüllungsort</b></h2>
          <p>
            EVENTBOX haftet in jedem Fall unbeschränkt nach dem
            Produkthaftungsgesetz, für vorsätzlich oder grob fahrlässig
            verursachte Schäden, bei arglistigem Verschweigen von Mängeln, sowie
            für Schäden aus der Verletzung des Lebens, des Körpers oder der
            Gesundheit. Auch die Haftung für Schäden aus der Verletzung einer
            Garantie ist unbeschränkt. In den in den Absätzen genannten Fällen
            und den Punkten 3 - 11 der Händlervereinbarung haftet EVENTBOX nicht
            für Schäden, die durch Fahrlässigkeit des Veranstalters verursacht
            wurden. Soweit die Haftung von EVENTBOX nach den vorstehenden
            Absätzen ausgeschlossen ist, gilt dies auch für die Haftung ihrer
            Erfüllungs- und Verrichtungsgehilfen. 11.4 Schlussklauseln Es gilt
            ausschließlich das Recht der Bundesrepublik Deutschland.. Alleiniger
            Erfüllungsort für Lieferung, Leistung und Zahlung ist München,
            sofern der Kunde Unternehmer i.S.v. § 14 BGB ist. Ist der Kunde
            Kaufmann, so ist (auch internationaler) Gerichtsstand für alle sich
            aus dem Vertragsverhältnis unmittelbar oder mittelbar ergebenen
            Streitigkeiten München. Dies gilt im Falle von grenzüberschreitenden
            Verträgen auch für Nichtkaufleute.
          </p>
          <br />

          <h2 class="text-center sm:font-20 md:font-30">
            <b>II. GETRÄNKE - SYSTEM</b>
          </h2>
          <br />
          <h2>
            <b>1. Haftung des Veranstalters bei Nutzung des Getränkesystems:</b>
          </h2>
          <p>
            Der Veranstalter übernimmt die volle Haftung für alle Handlungen im
            Zusammenhang mit dem Getränkesystem, das über das Zahlungsportal
            SUMUP Limited (ist ein E-Geld-Institut, das von der Central Bank of
            Ireland) betrieben wird. Insbesondere haftet der Veranstalter für
            alle Transaktionen, die über dieses System abgewickelt werden.
          </p>

          <h2>
            <b> 2. Einsehen von Transaktionsberichten: </b>
          </h2>
          <p>
            Der Veranstalter hat die Möglichkeit, sowohl die
            Transaktionsberichte über das SUMUP Limited (ist ein
            E-Geld-Institut, das von der Central Bank of Ireland) als auch über
            die Plattform oder Application EVENTBOX einzusehen. Der Zugang zu
            diesen Berichten liegt in der Verantwortung des Veranstalters und
            erfolgt gemäß den jeweils geltenden Nutzungsbedingungen der
            jeweiligen Plattformen.
          </p>

          <h2><b>3. Freiheit der Zahlungsmethoden:</b></h2>
          <p>
            Der Veranstalter ist berechtigt, nach eigenem Ermessen zu
            entscheiden, ob das Getränkesystem auf der Veranstaltung
            ausschließlich mit bargeldlosen Zahlungsmethoden (Cashless) oder mit
            Bargeld (Cash) betrieben wird. Der Veranstalter trägt die
            Verantwortung für die Auswahl und Umsetzung des gewählten Systems.
          </p>

          <h2>
            <b
              >4. Haftung des Veranstalters vor Ort und für
              Bargeldabrechnungen:</b
            >
          </h2>
          <p>
            Der Veranstalter haftet für sämtliche Vorgänge vor Ort bei der
            Veranstaltung, einschließlich der Durchführung von
            Bargeldabrechnungen. Jegliche Unregelmäßigkeiten oder Fehler im
            Umgang mit Bargeld sind ausschließlich vom Veranstalter zu
            verantworten.
          </p>

          <h2><b>5. Geräteverleih durch EVENTBOX :</b></h2>
          <p>
            Die EVENTBOX stellt dem Veranstalter bei Bedarf ausschließlich das
            notwendige Gerät sowie das dazugehörige SUMUP Limited
            Kartenlesegeräte zur Verfügung. Eine weitergehende Haftung von
            EVENTBOX beschränkt sich ausschließlich auf die Geräteverleihung.
            EVENTBOX übernimmt keine Haftung für die Handlungen des
            Veranstalters oder für das korrekte Funktionieren des Systems bei
            der Veranstaltung. Der Veranstalter haftet für alle Handlungen und
            die ordnungsgemäße Nutzung des bereitgestellten Gerätes und
            Terminals.
          </p>

          <h2>
            <b>6. Unveränderbarkeit des Systems und Transaktionsabläufe: </b>
          </h2>
          <p>
            Das Getränkesystem, das von EVENTBOX bereitgestellt wird und mit dem
            Zahlungsabwicklungssystem SUMUP Limited über eine Schnittstelle
            integriert ist, ist in seiner Funktionalität nicht veränderbar.
            Jegliche Manipulation oder Veränderung des Systems ist
            ausgeschlossen, da EVENTBOX keinen Zugriff auf den Zugang des
            Veranstalters hat, um Transaktionen zu beeinflussen oder die
            Funktionsweise zu modifizieren. Es gilt das Vier-Augen-Prinzip:
            sowohl der Zahlungsabwicklungspartner SUMUP Limited als auch
            Eventbox haben keinen Einfluss auf die Transaktionsabläufe. Alle
            Transaktionen durchlaufen zunächst SUMUP Limited, ein
            E-Geld-Institut, das von der Central Bank of Ireland reguliert wird,
            bevor sie über EVENTBOX weiterverarbeitet werden. EVENTBOX übernimmt
            keine Verantwortung für die Abwicklung oder das Verhalten des
            Zahlungsabwicklers SUMUP Limited.
          </p>

          <h2><b>7. Abwicklungsgebühr und Zahlungsabwicklung:</b></h2>
          <p>
            Der Veranstalter verpflichtet sich, an EVENTBOX eine
            Abwicklungsgebühr zu entrichten. Diese Abwicklungsgebühr umfasst den
            Verleih des Gerätes, die Nutzung des Getränkesystems sowie die
            anfallenden Transaktionsgebühren des Zahlungsabwicklungspartners
            SUMUP Limited. Eventbox ist berechtigt, die Transaktionsgebühren
            direkt an SUMUP Limited weiterzuleiten. Die genaue Höhe der
            Abwicklungsgebühr wird dem Veranstalter vor Vertragsabschluss
            mitgeteilt und ist Bestandteil der Vereinbarung.
          </p>

          <h2><b>8. Steuerliche Erfassung und Pflichten:</b></h2>
          <p>
            Beide Parteien – der Veranstalter und EVENTBOX – sind verpflichtet,
            ihre steuerlichen Pflichten eigenständig zu erfüllen und dafür Sorge
            zu tragen, dass alle relevanten Steuern korrekt erfasst, abgeführt
            und nach den jeweils geltenden gesetzlichen Vorschriften gehandhabt
            werden. EVENTBOX übernimmt keine Verantwortung für die steuerliche
            Erfassung oder Abwicklung des Veranstalters und dessen Einnahmen.
            Der Veranstalter verpflichtet sich, alle steuerlichen Anforderungen
            in Bezug auf seine Veranstaltung, insbesondere in Bezug auf die
            Bargeld- und Transaktionsabrechnungen, eigenverantwortlich zu
            erfüllen.
          </p>

          <h2><b>9. Verstöße gegen die AGB sowie Händlervereinbarung: </b></h2>
          <p>
            Bei Verstößen gegen diese Allgemeinen Geschäftsbedingungen (AGB)
            und/oder Händlervereinbarung behält sich Eventbox das Recht vor, die
            vereinbarten Dienstleistungen zu suspendieren oder zu beenden. Der
            Veranstalter haftet für alle Schäden, die durch einen Verstoß gegen
            die AGB und Händlervereinbarung entstehen, einschließlich, aber
            nicht beschränkt auf, unrechtmäßige Nutzung des Systems, oder
            Verletzung von steuerlichen Pflichten. EVENTBOX ist in diesem Fall
            berechtigt, von dem Veranstalter Schadensersatz zu verlangen und
            alle durch den Verstoß entstandenen Kosten und Auslagen geltend zu
            machen. 10.Schadenersatz und Berechnungsgrundlage: Im Falle eines
            Verstoßes gegen diese Allgemeinen Geschäftsbedingungen und
            Händlervereinbarung oder einer Pflichtverletzung, die zu Schäden
            führt, verpflichtet sich der Veranstalter, Eventbox den entstandenen
            Schaden zu ersetzen. Die Berechnungsgrundlage für den Schadenersatz
            richtet sich nach dem tatsächlichen Schaden, der durch die
            Pflichtverletzung entstanden ist, einschließlich, aber nicht
            beschränkt auf, direkte Kosten wie Reparaturen, Rückbuchungen,
            verlorene Einnahmen sowie sämtliche durch die Verletzung
            entstandenen Rechtsverfolgungskosten. Dies schließt auch
            Anwaltskosten und sonstige Kosten ein, die Eventbox aufgrund der
            Pflichtverletzung zur Durchsetzung ihrer Rechte oder zur
            Verteidigung gegen Forderungen aufwenden musste. Eventbox behält
            sich vor, den Schaden auf der Grundlage der durch den Verstoß
            entstandenen Mehrkosten, den entgangenen Gewinn sowie die
            angefallenen Anwaltskosten zu berechnen. Bei wiederholten oder
            schwerwiegenden Verstößen kann Eventbox zusätzlich eine
            pauschalierte Schadenersatzforderung erheben. Darüber hinaus ist
            EVENTBOX berechtigt, im Falle eines Verstoßes eine pauschalisierte
            Schadenersatzforderung zu erheben. Diese pauschale
            Schadenersatzforderung kann EVENTBOX je nach Höhe des entstandenen
            Schadens nach eigenem Ermessen festlegen und liegt im Bereich von
            mindestens 15.000 € bis maximal 300.000 €. Die genaue Höhe der
            pauschalierten Forderung wird von Eventbox nach freiem Ermessen und
            unter Berücksichtigung des Schadensausmaßes bestimmt. Im Falle einer
            solchen pauschalierten Schadenersatzforderung ist Eventbox
            berechtigt, den gesamten Betrag (bis zu 100%) direkt vom Umsatz des
            Veranstalters abzuziehen oder aus bereits bestehenden Zahlungen des
            Veranstalters zu verrechnen. Der Veranstalter erklärt sich damit
            einverstanden, dass Eventbox diese Forderung auf diesem Wege
            begleicht.
          </p>

          <div
            class="d-flex justify-content-center"
            v-if="$route.params.redirect"
          >
            <v-btn class="my-10 button-normal" @click="doAccept()">
              <span class="mx-20">{{ $t("accept") }}</span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    doAccept() {
      if (confirm(this.$t("confirm_organizer"))) {
        this.$router.push(`/${this.$route.params.redirect}`);
      }
    },
  },
};
</script>
