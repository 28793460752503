<template>
  <div class="sub-header-thin-div">
    <div :class="isMobile ? 'm-2' : 'w-75 mx-auto my-25 d-flex flex-column'">
      <div data-app class="m-5 p-5 box-white">
        <div :class="`center ${isMobile ? 'mt-1' : 'mt-5'}`">
          <span class="font-25 font-bold color-purple">{{ $t(`${$route.params.eventId ? 'edit_event' : 'add_event'}`) }}</span>
        </div>
        <div class="mt-5 browse-image">
          <img class="my-image" :src="photoUrl ? photoUrl : assets.add_event" />
          <input
            type="file"
            accept="image/*"
            v-on:change="onFileChanged"
            placeholder="Photo"
          />
        </div>
        <v-dialog v-model="visibleCropDialog" width="800px">
          <v-card class="box-white">
            <Cropper
              class="m-5"
              ref="cropper"
              :style="`height: ${isMobile ? '400' : '600'}px;`"
              :src="selectedPhoto"
              :stencil-props="{
                aspectRatio: 7 / 4
              }"
            />
            <div class="d-flex justify-content-end">
              <v-btn class="button-normal" @click="cropDone()">
                <span class="px-5">OK</span>
              </v-btn>
              <v-btn class="ml-5 button-normal" @click="visibleCropDialog = false;">
                <span class="px-5">{{ $t('cancel') }}</span>
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('event_name') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventInfo.name" maxlength="100" oninput="this.value = this.value.replace(/[^0-9a-zA-Z \S]/g,'');" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('start_date') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <DatePicker
              :class="`${isMobile ? 'w-50' : ''}`"
              v-model="startDate"
              format="DD.MM.YYYY"
              type="date"
              :placeholder="$t('start_date')"
            />
            <DatePicker
              :class="`${isMobile ? 'w-50' : 'ml-1'}`"
              v-model="startTime"
              format="HH:mm"
              type="time"
            />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('end_date') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <DatePicker
              :class="`${isMobile ? 'w-50' : ''}`"
              v-model="endDate"
              format="DD.MM.YYYY"
              type="date"
              :placeholder="$t('end_date')"
            />
            <DatePicker
              :class="`${isMobile ? 'w-50' : 'ml-1'}`"
              v-model="endTime"
              format="HH:mm"
              type="time"
            />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span>&nbsp;</span>
          <b-form-checkbox size="lg" :class="`${isMobile ? 'ml-3' : 'w-75'}`" v-model="visibleAddress">{{ $t('should_address_be_visible') }}</b-form-checkbox>
        </div>
        <div :class="`mt-1 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('address') }}</span>
          <gmap-autocomplete :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" :value="eventInfo.address" placeholder="" @place_changed="onPlaceChanged" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('genre') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'" style="position: relative;">
            <input class="input-normal w-100" v-model="genreString" disabled />
            <v-btn style="position:absolute; top:0; right:0;" icon @click="visibleGenreDialog = true">
              <img :src="assets.music_melody" width="20" />
            </v-btn>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('description') }}</span>
          <textarea :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventInfo.description" rows="5" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">Facebook Pixel ID</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventInfo.facebookPixelId" oninput="this.value = this.value.replace(/[^0-9]/g,'');" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">TikTok Pixel ID</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventInfo.tikTokPixelId" />
        </div>
        <div id="divDivider" class="my-10 mx-auto w-50 div-divider-h" />
        <div :class="`d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span>&nbsp;</span>
          <b-form-checkbox size="lg" :class="`${isMobile ? 'ml-3' : 'mb-5 w-75'}`" v-model="displayCountDown">{{ $t('display_event_count_down') }}</b-form-checkbox>
        </div>
        <div :class="`d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span>&nbsp;</span>
          <b-form-checkbox size="lg" :class="`${isMobile ? 'ml-3' : 'mb-5 w-75'}`" v-model="onlyVisibleToMe">{{ $t('only_visible_to_me') }}</b-form-checkbox>
        </div>
        <div class="mt-5 d-flex justify-content-center vertical-center" v-if="!onlyVisibleToMe">
          <span class="font-12">{{ $t('desc_want_sell_tickets_for_your_event') }}</span>
          <div class="mx-5 form-check vertical-center">
            <input class="form-check-input pointer" type="radio" name="hasTicket" id="hasTicket1" v-model="hasTicket" :value="true">
            <label class="form-check-label vertical-center pointer" for="hasTicket1">
              <span class="ml-1">{{ $t('yes') }}</span>
            </label>
          </div>
          <div class="form-check vertical-center">
            <input class="form-check-input pointer" type="radio" name="hasTicket" id="hasTicket2" v-model="hasTicket" :value="false">
            <label class="form-check-label vertical-center pointer" for="hasTicket2">
              <span class="ml-1">{{ $t('no') }}</span>
            </label>
          </div>
        </div>
        <div class="mt-5" v-if="hasTicket && !onlyVisibleToMe">
          <b-tabs content-class="mt-10" v-model="tabIndex">
            <b-tab :title="$t('ticket')">
              <div class="mt-10 d-flex">
                <div class="w-50 d-flex flex-column">
                  <span class="font-label ml-2">{{ $t('category') }}</span>
                  <input class="mt-1 form-control" v-model="ticketInfo.name" />
                </div>
                <div :class="`mx-1 d-flex flex-column ${isMobile ? 'w-120px' : 'w-25'}`">
                  <span class="font-label ml-2">{{ $t('price') }}</span>
                  <div class="mt-1 input-group">
                    <input class="form-control" v-model="ticketInfo.price" maxlength="8" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');" :disabled="ticketInfo.ticketId !== ''" />
                    <div class="input-group-append">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                </div>
                <div :class="`d-flex flex-column ${isMobile ? 'w-80px' : 'w-25'}`">
                  <span class="font-label ml-2">{{ $t('amount') }}</span>
                  <input class="mt-1 form-control" v-model="ticketInfo.totalQuantity" maxlength="5" oninput="this.value = this.value.replace(/[^0-9]/g,'');" />
                </div>
              </div>
              <div class="mt-5 d-flex flex-column">
                <span class="font-label ml-2">{{ $t('description') }}</span>
                <textarea class="mt-1 form-control" v-model="ticketInfo.description" rows="2" />
              </div>
              <div class="mt-5 d-flex justify-content-between vertical-center">
                <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" :class="`mt-5 ${isMobile ? 'w-200px' : 'w-250px'}`" v-model="isMultipleDaysTicket">{{ $t('multiple_days_ticket') }}</b-form-checkbox>
                <div class="w-100 d-flex">
                  <div class="w-50 ml-2 d-flex flex-column">
                    <span class="font-label ml-2">{{ $t('event_days') }}</span>
                    <input class="mt-1 form-control" v-model="ticketInfo.eventDays" maxlength="2" oninput="this.value = this.value.replace(/[^0-9]/g,'');" :disabled="!isMultipleDaysTicket" />
                  </div>
                </div>
              </div>
              <div class="mt-5 d-flex justify-content-between vertical-center">
                <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" :class="`mt-5 ${isMobile ? 'w-200px' : 'w-250px'}`" v-model="isGroupTicket" :disabled="isVIP">{{ $t('group_ticket') }}</b-form-checkbox>
                <div class="w-100 d-flex">
                  <div class="w-50 ml-2 d-flex flex-column">
                    <span class="font-label ml-2">{{ $t('minimum') }}</span>
                    <input class="mt-1 form-control" v-model="ticketInfo.minQuantity" maxlength="2" oninput="this.value = this.value.replace(/[^0-9]/g,'');" :disabled="isVIP || !isGroupTicket" />
                  </div>
                  <div class="w-50 ml-1 mr-auto d-flex flex-column">
                    <span class="font-label ml-2">{{ $t('maximum') }}</span>
                    <input class="mt-1 form-control" v-model="ticketInfo.maxQuantity" maxlength="2" oninput="this.value = this.value.replace(/[^0-9]/g,'');" :disabled="isVIP || !isGroupTicket" />
                  </div>
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-between vertical-center">
                <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" :class="`mt-5 ${isMobile ? 'w-200px' : 'w-250px'}`" v-model="isVIP">{{ $t('vip_ticket') }}</b-form-checkbox>
                <div class="w-100 ml-2 mr-auto d-flex flex-column">
                  <span class="font-label ml-2">{{ $t('vip_password') }}</span>
                  <div class="mt-1" style="position: relative;">
                    <input class="w-100 form-control" v-model="ticketInfo.vipPassword" :type="showPassword ? 'text' : 'password'" :disabled="!isVIP" />
                    <v-btn style="position:absolute; top:0; right:0;" icon @click="showPassword = !showPassword">
                      <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-between vertical-center">
                <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" :class="`mt-5 ${isMobile ? 'w-130px' : 'w-200px'}`" v-model="isSoldOut">{{ $t('sold_out') }}</b-form-checkbox>
                <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" :class="`mt-5 mr-auto ${isMobile ? '' : 'ml-2'}`" v-model="isHideTicket">{{ $t('hide_ticket') }}</b-form-checkbox>
                <v-btn class="ml-2 mt-5 button-small p-1" @click="saveTicket()">
                  <img :src="ticketInfo.ticketId ? assets.check : assets.plus" :width="20" />
                </v-btn>
              </div>
              <div class="my-5 justify-content-between vertical-center" v-for="(item, index) in ticketList" :key="(item, index)">
                <div class="mr-5 d-flex flex-column">
                  <div class="mx-auto my-5 w-75 div-divider-h" v-if="index === 0" />
                  <div class="d-flex justify-content-between vertical-center">
                    <span :class="`w-50 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                    <span :class="`ml-auto mr-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price) }}</span>
                    <span :class="`w-25 text-right ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
                  </div>
                  <div class="mt-2 ml-3 d-flex flex-column">
                    <span class="mt-1" v-if="item.description">{{ item.description }}</span>
                    <span class="mt-2 font-label font-bold" v-if="item.eventDays > 0">{{ $t('multiple_days_ticket') }} - {{ $t('days') }}: {{ item.eventDays }}</span>
                    <span class="mt-1 font-label font-bold" v-if="item.minQuantity && item.maxQuantity">{{ `${$t('group_ticket')} - ${$t('minimum')}: ${item.minQuantity}, ${$t('maximum')}: ${item.maxQuantity}` }}</span>
                    <i class="fa fa-crown font-20 color-purple" v-if="item.vipPassword"></i>
                    <span class="mt-1 font-label font-bold" v-if="item.hideTicket === true">{{ $t('hide_ticket') }}</span>
                    <span class="mt-1 font-label font-bold" v-if="item.soldOut === true">{{ $t('sold_out') }}</span>
                  </div>
                  <div class="mx-auto mt-5 w-75 div-divider-h" />
                </div>
                <v-btn icon small @click="editTicket(item)" v-if="item.ticketId">
                  <i :class="`ml-1 fa fa-edit color-purple ${isMobile ? 'font-15' : 'font-20'}`"></i>
                </v-btn>
                <v-btn icon small @click="ticketList.splice(index, 1)" v-else>
                  <i :class="`ml-1 flaticon-delete-1 color-red ${isMobile ? 'font-15' : 'font-20'}`"></i>
                </v-btn>
              </div>
            </b-tab>
            <b-tab :title="$t('promo_code')" :disabled="!$route.params.eventId">
              <div class="mt-10 d-flex">
                <div class="w-50 d-flex flex-column">
                  <span class="font-label ml-2">{{ $t('code') }}</span>
                  <input class="mt-1 form-control" v-model="promoInfo.code" maxlength="30" oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" />
                </div>
                <div :class="`mx-1 d-flex flex-column ${isMobile ? 'w-100px' : 'w-25'}`">
                  <span class="font-label ml-2">{{ $t('discount') }}</span>
                  <div class="mt-1 input-group">
                    <input class="form-control" v-model="promoInfo.percent" maxlength="2" oninput="this.value = this.value.replace(/[^0-9.]/g, '');" />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div class="w-25 d-flex flex-column">
                  <span class="font-label ml-2">{{ $t('amount') }}</span>
                  <input class="mt-1 form-control" v-model="promoInfo.totalQuantity" maxlength="5" oninput="this.value = this.value.replace(/[^0-9]/g,'');" />
                </div>
              </div>
              <div :class="`mt-5 d-flex vertical-center ${isMobile ? 'flex-column' : ''}`">
                <div :class="`vertical-center ${isMobile ? 'w-100' : 'w-500px'}`">
                  <span :class="isMobile ? 'ml-2 mb-1 font-12 w-130px' : 'font-15 w-180px'">{{ $t('start_date') }}</span>
                  <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" v-model="isPromoStartNow">{{ $t('now') }}</b-form-checkbox>
                </div>
                <div :class="isMobile ? 'mt-1' : ''">
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : ''}`"
                    v-model="promoStartDate"
                    format="DD.MM.YYYY"
                    type="date"
                    :placeholder="$t('start_date')"
                    :disabled="isPromoStartNow"
                  />
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                    v-model="promoStartTime"
                    format="HH:mm"
                    type="time"
                    :disabled="isPromoStartNow"
                  />
                </div>
              </div>
              <div :class="`mt-3 d-flex vertical-center ${isMobile ? 'flex-column' : ''}`">
                <div :class="`vertical-center ${isMobile ? 'w-100' : 'w-500px'}`">
                  <span :class="isMobile ? 'ml-2 mb-1 font-12 w-130px' : 'font-15 w-180px'">{{ $t('end_date') }}</span>
                  <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" v-model="isPromoEndWithEvent">{{ $t('when_ticket_sales_end') }}</b-form-checkbox>
                </div>
                <div :class="isMobile ? 'mt-1' : ''">
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : ''}`"
                    v-model="promoEndDate"
                    format="DD.MM.YYYY"
                    type="date"
                    :placeholder="$t('end_date')"
                    :disabled="isPromoEndWithEvent"
                  />
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                    v-model="promoEndTime"
                    format="HH:mm"
                    type="time"
                    :disabled="isPromoEndWithEvent"
                  />
                </div>
              </div>
              <div :class="`mt-3 d-flex vertical-center ${isMobile ? 'flex-column' : ''}`">
                <div :class="`vertical-center ${isMobile ? 'w-100' : 'w-500px'}`">
                  <span :class="isMobile ? 'ml-2 mb-1 font-12 w-130px' : 'font-15 w-180px'">{{ $t('apply_code_to') }}</span>
                  <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" v-model="isPromoForAllTickets">{{ $t('all_tickets') }}</b-form-checkbox>
                </div>
                <div :class="`d-flex justify-content-between ${isMobile ? 'mt-1 w-100' : 'flex-grow-1'}`">
                  <v-btn class="button-small" :disabled="isPromoForAllTickets" @click="openTicketDialog()">
                    <span :class="isMobile ? 'mx-2' : 'mx-5'">{{ $t('select_tickets') }}</span>
                  </v-btn>
                  <v-btn class="button-small ml-auto p-1" @click="savePromo()">
                    <img :src="promoInfo.promoId ? assets.check : assets.plus" :width="20" />
                  </v-btn>
                </div>
              </div>
              <div class="my-5 justify-content-between vertical-center" v-for="(item, index) in promoList" :key="(item, index)">
                <div class="mr-5 d-flex flex-column">
                  <div class="mx-auto my-5 div-divider-h" v-if="index === 0" />
                  <div class="d-flex justify-content-between vertical-center">
                    <span :class="`w-50 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.code }}</span>
                    <span :class="`ml-auto mr-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ `${item.percent}%` }}</span>
                    <span :class="`w-25 text-right ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.usedQuantity)} / ${item.totalQuantity > 0 ? getDecimalFormat(item.totalQuantity) : $t('unlimited')}` }}</span>
                  </div>
                  <div class="mt-2 ml-3 vertical-center">
                    <span class="font-label">{{ item.startAt ? getDateStringFromTimestamp(item.startAt) : $t('now') }}</span>
                    <span class="mx-2 font-label">-</span>
                    <span class="font-label">{{ item.endAt ? getDateStringFromTimestamp(item.endAt) : $t('when_ticket_sales_end') }}</span>
                  </div>
                  <div class="mx-auto mt-5 w-75 div-divider-h" />
                </div>
                <div class="d-flex flex-column">
                  <v-btn icon small @click="editPromo(item)" v-if="item.promoId">
                    <i :class="`fa fa-edit color-purple ${isMobile ? 'font-15' : 'font-20'}`"></i>
                  </v-btn>
                  <v-btn icon small @click="deletePromoTask(index, item.promoId)">
                    <i :class="`flaticon-delete-1 color-red ${isMobile ? 'font-15' : 'font-20'}`"></i>
                  </v-btn>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="my-20 d-flex justify-content-center">
          <v-btn class="button-normal" @click="setEventInfoTask()">
            <span :class="`${isMobile ? 'mx-20' : 'mx-40'}`">{{ $t(`${$route.params.eventId ? 'save' : 'add'}`) }}</span>
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="visibleGenreDialog" scrollable width="400px">
        <v-card>
          <v-card-title class="justify-content-between">
            <span class="font-bold font-18">{{ $t('select_event_genre') }}</span>
            <v-btn class="m-1" icon small @click="visibleGenreDialog = false">
              <i class="flaticon-cancel font-bold font-25 color-black"></i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <TreeView
              ref="treeView"
              :custom-options="treeOptions"
              :custom-styles="treeStyles"
              :nodes="treeNodes"
            />
          </v-card-text>
          <div class="m-3 d-flex justify-content-end">
            <v-btn class="button-normal" @click="selectGenres()">
              <span class="px-5">OK</span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="visibleTicketDialog" scrollable width="400px">
        <v-card>
          <v-card-title class="justify-content-between">
            <span class="font-bold font-18">{{ $t('select_tickets') }}</span>
            <v-btn class="m-1" icon small @click="visibleTicketDialog = false">
              <i class="flaticon-cancel font-bold font-25 color-black"></i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="my-5 justify-content-between vertical-center" v-for="(item, index) in ticketList.filter(element => element.ticketId && element.price && element.soldOut !== true && element.hideTicket !== true)" :key="(item, index)">
              <input :id="`check_${index}`" class="pointer" :value="item.ticketId" type="checkbox" checked v-model="ticketIDs" />
              <label :for="`check_${index}`" :class="`mt-1 ml-3 pointer font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</label>
              <span :class="`ml-auto mr-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(item.price) }}</span>
            </div>
          </v-card-text>
          <div class="m-3 d-flex justify-content-end">
            <v-btn class="button-normal" @click="selectTickets()">
              <span class="px-5">OK</span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style scoped>
  .browse-image {
    margin: auto;
    position: relative;
    width: 300px;
    height: auto;
  }
  .browse-image input, browse div {
    width: 300px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .browse-image input {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
  }
  .my-image {
    width: 300px;
    height: auto;
    object-fit: cover;
  }
  input[type=checkbox] {
    transform: scale(1.2);
}
</style>

<script>
import TreeView from 'vuejs-tree';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Reducer from 'image-blob-reduce';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { functions, firestore } from '../../../main';
import { onSnapshot, doc, query, collection, where, Timestamp } from 'firebase/firestore';
import { getCurDateString, getDateStringFromTimestamp, isFutureTimestamp, toInt, getRoundValue, getDecimalFormat, getPriceFormat, showLoading, showFunctionError } from '../../../functions';

import add_event from '@/assets/images/add_event.png';
import music_melody from '@/assets/images/music_melody.png';
import plus from '@/assets/images/plus.png';
import check from '@/assets/images/check.png';

export default {
  name: 'EditEvent',
  components: {
    TreeView,
    DatePicker,
    Cropper
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  data() {
    const curDate = new Date();
    return {
      treeStyles: {
        tree: {
          height: "auto",
          maxHeight: "500px",
          overflowY: "visible",
          display: "inline-block",
          textAlign: "left",
        },
        row: {
          style: {
            width: "300px",
            cursor: "pointer",
          },
          child: {
            class: "",
            style: {
              height: "35px",
            },
            active: {
              style: {
                height: "35px",
              },
            },
          },
        },
        rowIndent: {
          paddingLeft: "20px",
        },
        text: {
          class: "font-18",
          style: {},
          active: {}
        }
      },
      treeOptions: {
        treeEvents: {
          expanded: {
            state: true,
            fn: this.onTreeNameClicked
          },
          collapsed: {
            state: true,
            fn: this.onTreeNameClicked
          },
          selected: {
            state: false,
          },
          checked: {
            state: true,
            fn: this.onTreeCheckboxClicked,
          },
        },
        events: {
          expanded: {
            state: true,
          },
          selected: {
            state: false,
          },
          checked: {
            state: true,
          },
          editableName: {
            state: true,
            calledEvent: "expanded",
          },
        },
        addNode: {
          state: false,
          fn: null,
          appearOnHover: false,
        },
        editNode: {
          state: false,
          fn: null,
          appearOnHover: false
        },
        deleteNode: {
          state: false,
          fn: null,
          appearOnHover: true,
        },
        showTags: false
      },
      treeNodes: [
        {
          id: 100,
          text: 'Festivals',
          checkable: false,
          nodes: [
            {
              id: 101,
              parentId: 100,
              text: 'Rock',
              value: 'Festivals / Rock'
            },
            {
              id: 102,
              parentId: 100,
              text: 'Indie',
              value: 'Festivals / Indie'
            },
            {
              id: 103,
              parentId: 100,
              text: 'Alternativ',
              value: 'Festivals / Alternativ'
            },
            {
              id: 104,
              parentId: 100,
              text: 'Elektro',
              value: 'Festivals / Elektro'
            },
            {
              id: 105,
              parentId: 100,
              text: 'Techno',
              value: 'Festivals / Techno'
            },
            {
              id: 106,
              parentId: 100,
              text: 'House',
              value: 'Festivals / House'
            },
            {
              id: 107,
              parentId: 100,
              text: 'Hip Hop',
              value: 'Festivals / Hip Hop'
            },
            {
              id: 108,
              parentId: 100,
              text: 'RnB',
              value: 'Festivals / RnB'
            },
            {
              id: 109,
              parentId: 100,
              text: 'Gay & Lesbian',
              value: 'Festivals / Gay & Lesbian'
            },
            {
              id: 110,
              parentId: 100,
              text: 'Reggae',
              value: 'Festivals / Reggae'
            },
            {
              id: 111,
              parentId: 100,
              text: 'Dancehall',
              value: 'Festivals / Dancehall'
            },
            {
              id: 112,
              parentId: 100,
              text: 'Latino',
              value: 'Festivals / Latino'
            },
            {
              id: 113,
              parentId: 100,
              text: 'Reggaeton',
              value: 'Festivals / Reggaeton'
            },
            {
              id: 114,
              parentId: 100,
              text: 'Türkisch',
              value: 'Festivals / Türkisch'
            }
          ],
        },
        {
          id: 200,
          text: 'Clubs',
          checkable: false,
          nodes: [
            {
              id: 201,
              parentId: 200,
              text: 'Rock',
              value: 'Clubs / Rock'
            },
            {
              id: 202,
              parentId: 200,
              text: 'Indie',
              value: 'Clubs / Indie'
            },
            {
              id: 203,
              parentId: 200,
              text: 'Alternativ',
              value: 'Clubs / Alternativ'
            },
            {
              id: 204,
              parentId: 200,
              text: 'Elektro',
              value: 'Clubs / Elektro'
            },
            {
              id: 205,
              parentId: 200,
              text: 'Techno',
              value: 'Clubs / Techno'
            },
            {
              id: 206,
              parentId: 200,
              text: 'House',
              value: 'Clubs / House'
            },
            {
              id: 207,
              parentId: 200,
              text: 'Hip Hop',
              value: 'Clubs / Hip Hop'
            },
            {
              id: 208,
              parentId: 200,
              text: 'RnB',
              value: 'Clubs / RnB'
            },
            {
              id: 209,
              parentId: 200,
              text: 'Gay & Lesbian',
              value: 'Clubs / Gay & Lesbian'
            },
            {
              id: 210,
              parentId: 200,
              text: 'Reggae',
              value: 'Clubs / Reggae'
            },
            {
              id: 211,
              parentId: 200,
              text: 'Dancehall',
              value: 'Clubs / Dancehall'
            },
            {
              id: 212,
              parentId: 200,
              text: 'Charts',
              value: 'Clubs / Charts'
            },
            {
              id: 213,
              parentId: 200,
              text: 'Deutsche Welle',
              value: 'Clubs / Deutsche Welle'
            },
            {
              id: 214,
              parentId: 200,
              text: 'Latino',
              value: 'Clubs / Latino'
            },
            {
              id: 215,
              parentId: 200,
              text: 'Reggaeton',
              value: 'Clubs / Reggaeton'
            },
            {
              id: 216,
              parentId: 200,
              text: 'Türkisch',
              value: 'Clubs / Türkisch'
            }
          ],
        },
        {
          id: 300,
          text: 'Concerts',
          checkable: false,
          nodes: [
            {
              id: 301,
              parentId: 300,
              text: 'Hip Hop',
              value: 'Concerts / Hip Hop'
            },
            {
              id: 302,
              parentId: 300,
              text: 'Blues',
              value: 'Concerts / Blues'
            },
            {
              id: 303,
              parentId: 300,
              text: 'Country',
              value: 'Concerts / Country'
            },
            {
              id: 304,
              parentId: 300,
              text: 'Electronic',
              value: 'Concerts / Electronic'
            },
            {
              id: 305,
              parentId: 300,
              text: 'Folk',
              value: 'Concerts / Folk'
            },
            {
              id: 306,
              parentId: 300,
              text: 'Gospel',
              value: 'Concerts / Gospel'
            },
            {
              id: 307,
              parentId: 300,
              text: 'Hard & Heavy',
              value: 'Concerts / Hard & Heavy'
            },
            {
              id: 308,
              parentId: 300,
              text: 'Independent',
              value: 'Concerts / Independent'
            },
            {
              id: 309,
              parentId: 300,
              text: 'Jazz',
              value: 'Concerts / Jazz'
            },
            {
              id: 310,
              parentId: 300,
              text: 'Latin',
              value: 'Concerts / Latin'
            },
            {
              id: 311,
              parentId: 300,
              text: 'Latino',
              value: 'Concerts / Latino'
            },
            {
              id: 312,
              parentId: 300,
              text: 'Reggaeton',
              value: 'Concerts / Reggaeton'
            },
            {
              id: 313,
              parentId: 300,
              text: 'Türkisch',
              value: 'Concerts / Türkisch'
            }
          ]
        },
        {
          id: 400,
          text: 'Party',
          checkable: false,
          nodes: [
            {
              id: 401,
              parentId: 400,
              text: 'Rock',
              value: 'Party / Rock'
            },
            {
              id: 402,
              parentId: 400,
              text: 'Indie',
              value: 'Party / Indie'
            },
            {
              id: 403,
              parentId: 400,
              text: 'Alternativ',
              value: 'Party / Alternativ'
            },
            {
              id: 404,
              parentId: 400,
              text: 'Elektro',
              value: 'Party / Elektro'
            },
            {
              id: 405,
              parentId: 400,
              text: 'Techno',
              value: 'Party / Techno'
            },
            {
              id: 406,
              parentId: 400,
              text: 'House',
              value: 'Party / House'
            },
            {
              id: 407,
              parentId: 400,
              text: 'Hip Hop',
              value: 'Party / Hip Hop'
            },
            {
              id: 408,
              parentId: 400,
              text: 'RnB',
              value: 'Party / RnB'
            },
            {
              id: 409,
              parentId: 400,
              text: 'Gay & Lesbian',
              value: 'Party / Gay & Lesbian'
            },
            {
              id: 410,
              parentId: 400,
              text: 'Reggae',
              value: 'Party / Reggae'
            },
            {
              id: 411,
              parentId: 400,
              text: 'Dancehall',
              value: 'Party / Dancehall'
            },
            {
              id: 412,
              parentId: 400,
              text: 'Latino',
              value: 'Party / Latino'
            },
            {
              id: 413,
              parentId: 400,
              text: 'Reggaeton',
              value: 'Party / Reggaeton'
            },
            {
              id: 414,
              parentId: 400,
              text: 'Türkisch',
              value: 'Party / Türkisch'
            }
          ]
        },
        {
          id: 500,
          text: 'Kultur',
          checkable: false,
          nodes: [
            {
              id: 501,
              parentId: 500,
              text: 'Theater',
              value: 'Kultur / Theater'
            },
            {
              id: 502,
              parentId: 500,
              text: 'Ausstellungen',
              value: 'Kultur / Ausstellungen'
            },
            {
              id: 503,
              parentId: 500,
              text: 'Designmärkte',
              value: 'Kultur / Designmärkte'
            },
            {
              id: 504,
              parentId: 500,
              text: 'Flohmarkt',
              value: 'Kultur / Flohmarkt'
            },
            {
              id: 505,
              parentId: 500,
              text: 'Kino',
              value: 'Kultur / Kino'
            },
            {
              id: 506,
              parentId: 500,
              text: 'Strassenfeste',
              value: 'Kultur / Strassenfeste'
            },
            {
              id: 507,
              parentId: 500,
              text: 'Latino',
              value: 'Kultur / Latino'
            },
            {
              id: 508,
              parentId: 500,
              text: 'Reggaeton',
              value: 'Kultur / Reggaeton'
            },
            {
              id: 509,
              parentId: 500,
              text: 'Türkisch',
              value: 'Kultur / Türkisch'
            }
          ]
        },
        {
          id: 600,
          text: 'Food & Drinks',
          checkable: false,
          nodes: [
            {
              id: 601,
              parentId: 600,
              text: 'Food & Drinks',
              value: 'Food & Drinks'
            }
          ]
        },
        {
          id: 700,
          text: 'Sportevents',
          checkable: false,
          nodes: [
            {
              id: 701,
              parentId: 700,
              text: 'Sportevents',
              value: 'Sportevents'
            }
          ]
        },
        {
          id: 800,
          text: 'Happening',
          checkable: false,
          nodes: [
            {
              id: 801,
              parentId: 800,
              text: 'Happening',
              value: 'Happening'
            }
          ]
        },
        {
          id: 900,
          text: 'Fairs & Conferences',
          checkable: false,
          nodes: [
            {
              id: 901,
              parentId: 900,
              text: 'Fairs & Conferences',
              value: 'Fairs & Conferences'
            },
          ]
        },
        {
          id: 1000,
          text: 'Afterwork',
          checkable: false,
          nodes: [
            {
              id: 1001,
              parentId: 1000,
              text: 'Afterwork',
              value: 'Afterwork'
            }
          ]
        }
      ],
      assets: {
        add_event,
        music_melody,
        plus,
        check
      },
      selectedPhoto: '',
      cropResult: null,
      photoUrl: '',      
      visibleCropDialog: false,
      showCrop: false,
      startDate: null,
      startTime: curDate,
      endDate: null,
      endTime: curDate,
      promoStartDate: null,
      promoStartTime: curDate,
      promoEndDate: null,
      promoEndTime: curDate,
      genreString: '',
      showTimeRangePanel: false,
      visibleGenreDialog: false,
      visibleTicketDialog: false,
      eventInfo: {
        eventId: '',
        name: '',
        photoUrl: '',
        mediaRatio: 0,
        startAt: null,
        endAt: null,
        hideAddress: false,
        address: '',
        geoPoint: null,
        genres: [],
        description: '',
        facebookPixelId: '',
        tikTokPixelId: ''
      },
      ticketInfo: {
        ticketId: '',
        name: '',
        price: '',
        totalQuantity: '',
        soldQuantity: 0,
        minQuantity: '',
        maxQuantity: '',
        vipPassword: ''
      },
      promoInfo: {
        promoId: '',
        code: '',
        percent: '',
        totalQuantity: '',
        usedQuantity: 0,
        ticketIDs: []
      },
      tabIndex: 0,
      visibleAddress: true,
      onlyVisibleToMe: false,
      displayCountDown: true,
      hasTicket: true,
      isMultipleDaysTicket: false,
      isGroupTicket: false,
      isVIP: false,
      isSoldOut: false,
      isHideTicket: false,
      showPassword: false,
      isPromoStartNow: true,
      isPromoEndWithEvent: true,
      isPromoForAllTickets: true,
      ticketList: [],
      promoList: [],
      ticketIDs: [],
      snapEventInfo: null,
      snapTicketList: null,
      snapPromoList: null
    };
  },
  mounted() {
    if (this.checkPermission()) {
      this.getEventInfoTask();
      this.getTicketListTask();
      this.getPromoListTask();
    }
  },
  destroyed() {
    if (this.snapEventInfo) {
      this.snapEventInfo();
    }
    if (this.snapTicketList) {
      this.snapTicketList();
    }
    if (this.snapPromoList) {
      this.snapPromoList();
    }
  },
  methods: {
    getDateStringFromTimestamp,
    getDecimalFormat,
    getPriceFormat,
    onFileChanged(event) {
      if (event.target.files && event.target.files.length > 0) {
        const params = {
          max: 2900,
          unsharpAmount: 80,
          unsharpRadius: 0.6,
          unsharpThreshold: 2,
        };
        Reducer().toBlob(event.target.files[0], params).then(blob => {
          this.selectedPhoto = URL.createObjectURL(blob);
          this.eventInfo.photoUrl = '';
          this.eventInfo.mediaRatio = 4 / 7;
          this.visibleCropDialog = true;
        });
      }
    },
    cropDone() {
      this.visibleCropDialog = false
      const result = this.$refs.cropper.getResult();
      if (result) {
        const cropResult = result.canvas.toDataURL('image/jpeg');
        if (cropResult) {
          this.photoUrl = cropResult;
          this.cropResult = cropResult;
        }
      }
    },
    onPlaceChanged(placeData) {
      if (placeData.formatted_address && placeData.geometry && placeData.geometry.location) {
        const location = JSON.parse(JSON.stringify(placeData.geometry.location));
        this.eventInfo.address = placeData.formatted_address;
        this.eventInfo.geoPoint = { latitude: location.lat, longitude: location.lng };
      }
    },
    onTreeNameClicked(nodeId) {
      const parentId = parseInt(nodeId / 100) * 100;
      const parentNode = this.treeNodes.find(element => element.id === parentId);
      if (parentId !== nodeId && parentNode) {
        const childNode = parentNode.nodes.find(element => element.id === nodeId);
        if (childNode) {
          if (childNode.state.checked === true) {
            this.$refs['treeView'].uncheckNode(nodeId);
          } else {
            const checkedNodes = this.$refs['treeView'].getCheckedNodes('parentId');
            if (checkedNodes && checkedNodes.length > 0 && !checkedNodes.includes(parentId)) {
              this.$refs['treeView'].uncheckAllNodes();
              this.$refs['treeView'].checkNode(nodeId);
            } else if (checkedNodes.length > 1) {
              this.$toast.error(this.$t('alert_only_select_2_categories_in_max'));
            } else {
              this.$refs['treeView'].checkNode(nodeId);
            }
          }
        }
      }
    },
    onTreeCheckboxClicked(nodeId, state) {
      const parentId = parseInt(nodeId / 100) * 100;
      if (parentId !== nodeId && state === true) {
        var isAnotherParent = false;
        const checkedNodes = this.$refs['treeView'].getCheckedNodes('parentId');
        if (checkedNodes) {
          checkedNodes.forEach(id => {
            if (id !== parentId) {
              isAnotherParent = true;
            }
          });
        }
        if (isAnotherParent === true) {
          this.$refs['treeView'].uncheckAllNodes();
          this.$refs['treeView'].checkNode(nodeId);
        } else if (checkedNodes.length > 2) {
          this.$toast.error(this.$t('alert_only_select_2_categories_in_max'));
          this.$refs['treeView'].uncheckNode(nodeId);
        }
      }
    },
    checkPermission() {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          return true;
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
          return false;
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
        return false;
      }
    },
    selectGenres() {
      const checkedNodes = this.$refs['treeView'].getCheckedNodes('value');
      if (checkedNodes) {
        this.eventInfo.genres = checkedNodes;
        this.genreString = checkedNodes.join(', ');
      }
      this.visibleGenreDialog = false;
    },
    openTicketDialog() {
      const ticketList = this.ticketList.filter(element => element.ticketId && element.price && element.soldOut !== true && element.hideTicket !== true);
      if (ticketList.length > 0) {
        this.ticketIDs = JSON.parse(JSON.stringify(this.promoInfo.ticketIDs));
        this.visibleTicketDialog = true;
      } else {
        this.tabIndex = 0;
        this.$toast.error(this.$t('alert_add_event_tickets'));
      }
    },
    selectTickets() {
      this.promoInfo.ticketIDs = JSON.parse(JSON.stringify(this.ticketIDs));
      this.visibleTicketDialog = false;
    },
    saveTicket() {
      if (!this.checkPermission()) {
        return;
      }
      const ticketInfo = {
        ticketId: this.ticketInfo.ticketId,
        name: this.ticketInfo.name,
        price: getRoundValue(this.ticketInfo.price) * 100,
        totalQuantity: toInt(this.ticketInfo.totalQuantity),
        description: this.ticketInfo.description,
        soldQuantity: this.ticketInfo.soldQuantity,
        eventDays: this.isMultipleDaysTicket ? toInt(this.ticketInfo.eventDays) : 0,
        minQuantity: this.isGroupTicket ? toInt(this.ticketInfo.minQuantity) : 0,
        maxQuantity: this.isGroupTicket ? toInt(this.ticketInfo.maxQuantity) : 0,
        vipPassword: this.ticketInfo.vipPassword,
        soldOut: this.isSoldOut,
        hideTicket: this.isHideTicket
      }
      if (ticketInfo.price > 0 && (!this.myInfo.organizerInfo || !this.myInfo.organizerInfo.cardNumber)) {
        if (window.confirm(this.$t('confirm_edit_card_info'))) {
          this.$router.push('/profile');
        }
        return;
      }
      if (!ticketInfo.name) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('category')]));
        return;
      }
      if (!ticketInfo.totalQuantity) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('amount')]));
        return;
      }
      if (this.isVIP) {
        if (!ticketInfo.vipPassword) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('vip_password')]));
          return;
        }
        ticketInfo.minQuantity = 0;
        ticketInfo.maxQuantity = 0;
      } else if (this.isGroupTicket) {
        if (!ticketInfo.minQuantity) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('minimum')]));
          return;
        }
        if (!ticketInfo.maxQuantity) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('maximum')]));
          return;
        }
        const maxQuantityLimit = Math.min(ticketInfo.totalQuantity, this.MAX_QUANTITY_LIMIT);
        if (ticketInfo.minQuantity > maxQuantityLimit) {
          this.$toast.error(this.$t('alert_minimum_must_be_less_than_', [maxQuantityLimit]));
          return;
        }
        if (ticketInfo.maxQuantity > maxQuantityLimit) {
          this.$toast.error(this.$t('alert_maximum_must_be_less_than_', [maxQuantityLimit]));
          return;
        }
        if (ticketInfo.minQuantity > ticketInfo.maxQuantity) {
          this.$toast.error(this.$t('alert_maximum_must_be_grater_minimum'));
          return;
        }
      }
      if (this.isMultipleDaysTicket) {
        if (!ticketInfo.eventDays) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('event_days')]));
          return;
        }
      }
      if (ticketInfo.ticketId) {
        const index = this.ticketList.findIndex(element => element.ticketId === ticketInfo.ticketId);
        if (index !== -1) {
          this.ticketList[index] = ticketInfo;
        }
      } else {
        this.ticketList.push(ticketInfo);
      }
      this.ticketInfo = {
        ticketId: '',
        name: '',
        price: '',
        totalQuantity: '',
        description: '',
        soldQuantity: 0,
        eventDays: '',
        minQuantity: '',
        maxQuantity: '',
        vipPassword: ''
      };
      this.isVIP = false;
      this.isMultipleDaysTicket = false;
      this.isGroupTicket = false;
      this.isSoldOut = false;
      this.isHideTicket = false;
    },
    editTicket(ticketInfo) {
      this.ticketInfo = {
        ticketId: ticketInfo.ticketId,
        name: ticketInfo.name,
        price: getRoundValue(ticketInfo.price / 100),
        totalQuantity: ticketInfo.totalQuantity,
        description: ticketInfo.description || '',
        soldQuantity: ticketInfo.soldQuantity,
        eventDays: ticketInfo.eventDays > 0 ? ticketInfo.eventDays.toString() : '',
        minQuantity: ticketInfo.minQuantity > 0 ? ticketInfo.minQuantity.toString() : '',
        maxQuantity: ticketInfo.maxQuantity > 0 ? ticketInfo.maxQuantity.toString() : '',
        vipPassword: ticketInfo.vipPassword
      };
      this.isVIP = ticketInfo.vipPassword.length > 0;
      this.isMultipleDaysTicket = ticketInfo.eventDays > 0;
      this.isGroupTicket = ticketInfo.minQuantity > 0 && ticketInfo.maxQuantity > 0;
      this.isSoldOut = ticketInfo.soldOut || false;
      this.isHideTicket = ticketInfo.hideTicket || false;
      document.getElementById('divDivider').scrollIntoView();
    },
    savePromo() {
      if (!this.checkPermission()) {
        return;
      }
      const promoInfo = {
        promoId: this.promoInfo.promoId,
        code: this.promoInfo.code,
        percent: toInt(this.promoInfo.percent),
        totalQuantity: toInt(this.promoInfo.totalQuantity),
        usedQuantity: this.promoInfo.usedQuantity,
        ticketIDs: [],
        startAt: null,
        endAt: null
      }
      if (!promoInfo.code) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('code')]));
        return;
      }
      if (this.promoList.find(element => element.promoId !== promoInfo.promoId && element.code === promoInfo.code)) {
        this.$toast.error(this.$t('alert_duplicate_promo_code'));
        return;
      }
      if (!promoInfo.percent) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('discount')]));
        return;
      }
      if (!this.isPromoStartNow) {
        if (!this.promoStartDate) {
          this.$toast.error(this.$t('alert_select_start_date'));
          return;
        }
        this.promoStartDate.setHours(this.promoStartTime.getHours());
        this.promoStartDate.setMinutes(this.promoStartTime.getMinutes());
        promoInfo.startAt = Timestamp.fromDate(this.promoStartDate);
      }
      if (!this.isPromoEndWithEvent) {
        if (!this.isPromoEndWithEvent && !this.promoEndDate) {
          this.$toast.error(this.$t('alert_select_end_date'));
          return;
        }
        this.promoEndDate.setHours(this.promoEndTime.getHours());
        this.promoEndDate.setMinutes(this.promoEndTime.getMinutes());
        promoInfo.endAt = Timestamp.fromDate(this.promoEndDate);
      }
      if (this.promoStartDate && this.promoEndDate && this.promoEndDate.getTime() < this.promoStartDate.getTime()) {
        this.$toast.error(this.$t('alert_end_date_must_be_greater_than_start_date'));
        return;
      }
      if (!this.isPromoForAllTickets) {
        if (this.promoInfo.ticketIDs.length === 0) {
          this.$toast.error(this.$t('alert_select_tickets_for_promo_code'));
          return;
        }
        promoInfo.ticketIDs = this.promoInfo.ticketIDs;
      }
      if (promoInfo.promoId) {
        const index = this.promoList.findIndex(element => element.promoId === promoInfo.promoId);
        if (index !== -1) {
          this.promoList[index] = promoInfo;
        }
      } else {
        this.promoList.push(promoInfo);
      }
      this.promoInfo = {
        promoId: '',
        code: '',
        percent: '',
        totalQuantity: '',
        usedQuantity: 0,
        ticketIDs: []
      };
      this.isPromoStartNow = true;
      this.isPromoEndWithEvent = true;
      this.isPromoForAllTickets = true;
      this.promoStartDate = null;
      this.promoStartTime = new Date();
      this.promoEndDate = null;
      this.promoEndTime = new Date();
    },
    editPromo(promoInfo) {
      this.promoInfo = {
        promoId: promoInfo.promoId,
        code: promoInfo.code,
        percent: promoInfo.percent,
        totalQuantity: promoInfo.totalQuantity,
        usedQuantity: promoInfo.usedQuantity,
        ticketIDs: promoInfo.ticketIDs || []
      };
      if (promoInfo.startAt) {
        const startDate = promoInfo.startAt.toDate();
        this.promoStartDate = startDate;
        this.promoStartTime = startDate;
        this.isPromoStartNow = false;
      } else {
        this.isPromoStartNow = true;
      }
      if (promoInfo.endAt) {
        const endDate = promoInfo.endAt.toDate();
        this.promoEndDate = endDate;
        this.promoEndTime = endDate;
        this.isPromoEndWithEvent = false;
      } else {
        this.isPromoEndWithEvent = true;
      }
      this.isPromoForAllTickets = promoInfo.ticketIDs.length === 0;
      document.getElementById('divDivider').scrollIntoView();
    },
    getEventInfoTask() {
      if (this.snapEventInfo || !this.$route.params.eventId) {
        return;
      }
      const loader = showLoading(this);
      this.snapEventInfo = onSnapshot(doc(firestore, 'event', this.$route.params.eventId), doc => {
        loader.hide();
        const eventInfo = doc.data();
        if (!eventInfo) {
          this.$toast.error('No event found!');
          this.$router.push(`/my_events`);
        } else if (eventInfo.deletedAt) {
          this.$toast.error(this.$t('alert_this_event_has_been_deleted'));
          this.$router.push(`/my_events`);
        } else if (eventInfo.userId !== this.$store.state.myId) {
          this.$toast.error('This event is not yours!');
          this.$router.push(`/my_events`);
        } else if (!isFutureTimestamp(eventInfo.endAt)) {
          this.$toast.error('This event is already expired!');
          this.$router.push(`/my_events`);
        } else {
          const startDate = eventInfo.startAt.toDate();
          const endDate = eventInfo.endAt.toDate();
          this.eventInfo = eventInfo;
          this.photoUrl = eventInfo.photoUrl;
          this.startDate = startDate;
          this.startTime = startDate;
          this.endDate = endDate;
          this.endTime = endDate;
          this.genreString = eventInfo.genres.join(', ');
          this.visibleAddress = !eventInfo.hideAddress;
          this.onlyVisibleToMe = eventInfo.onlyVisibleToMe || false;
          this.displayCountDown = eventInfo.displayCountDown || false;
        }
      });
    },
    getTicketListTask() {
      if (this.snapTicketList || !this.$route.params.eventId) {
        return;
      }
      const q = query(collection(firestore, 'ticket'), where('eventId', '==', this.$route.params.eventId));
      this.snapTicketList = onSnapshot(q, querySnapshot => {
        const ticketList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (!('totalQuantity' in data)) {
            data['totalQuantity'] = 0;
          }
          if (!('soldQuantity' in data)) {
            data['soldQuantity'] = 0;
          }
          if (!('minQuantity' in data)) {
            data['minQuantity'] = 0;
          }
          if (!('maxQuantity' in data)) {
            data['maxQuantity'] = 0;
          }
          if (!('eventDays' in data)) {
            data['eventDays'] = 0;
          }
          ticketList.push(data);
        });
        this.ticketList = ticketList;
        this.hasTicket = ticketList.length > 0;
      });
    },
    getPromoListTask() {
      if (this.snapPromoList || !this.$route.params.eventId) {
        return;
      }
      const q = query(collection(firestore, 'promoCode'), where('eventId', '==', this.$route.params.eventId));
      this.snapPromoList = onSnapshot(q, querySnapshot => {
        const promoList = [];
        querySnapshot.forEach(doc => promoList.push(doc.data()));
        this.promoList = promoList;
      });
    },
    uploadPhotoTask() {
      const metadata = { contentType: 'image/jpeg' };
      const storage = getStorage();
      const storageRef = ref(storage, `EventPhotos/${getCurDateString('YYYY-MM-DD')}/${this.eventInfo.userId}_${getCurDateString()}.jpg`);
      const loader = showLoading(this, 1);
      uploadString(storageRef, this.cropResult, 'data_url', metadata).then(snapshot => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
          loader.hide();
          this.eventInfo.photoUrl = downloadURL;
          this.setEventInfoTask();
        });
      }).catch(error => {
        loader.hide();
        this.$toast.error(this.$t('alert_unexpected_error'));
      });
    },
    deletePromoTask(index, promoId) {
      if (promoId) {
        if (!this.checkPermission()) {
          return;
        }
        if (!confirm(this.$t('confirm_delete'))) {
          return;
        }
        const params = {
          userId: this.$store.state.myId,
          isDeletePromoCode: true,
          promoId: promoId
        }
        const loader = showLoading(this, 1);
        const func = httpsCallable(functions, 'setEventInfo');
        func(JSON.stringify(params)).then(response => {
          loader.hide();
          if (response.data !== this.RESULT_SUCCESS) {
            showFunctionError(this, response.data);
          }
        }).catch(error => {
          loader.hide();
          this.$toast.error(error.code + ', ' + error.message);
        });
      } else {
        this.promoList.splice(index, 1);
      }
    },
    setEventInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.$route.params.eventId && !this.cropResult) {
        this.$toast.error(this.$t('alert_select_photo'));
        return;
      }
      if (!this.eventInfo.name) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('event_name')]));
        return;
      }
      if (this.eventInfo.name.length < 4) {
        this.$toast.error(this.$t('alert_min_characters', [4, this.$t('event_name')]));
        return;
      }
      if (!this.startDate) {
        this.$toast.error(this.$t('alert_select_start_date'));
        return;
      }
      if (!this.endDate) {
        this.$toast.error(this.$t('alert_select_end_date'));
        return;
      }
      this.startDate.setHours(this.startTime.getHours());
      this.startDate.setMinutes(this.startTime.getMinutes());
      this.endDate.setHours(this.endTime.getHours());
      this.endDate.setMinutes(this.endTime.getMinutes());
      if (this.endDate.getTime() < this.startDate.getTime()) {
        this.$toast.error(this.$t('alert_end_date_must_be_greater_than_start_date'));
        return;
      }
      this.eventInfo.startAt = Timestamp.fromDate(this.startDate);
      this.eventInfo.endAt = Timestamp.fromDate(this.endDate);
      if (!this.eventInfo.address || !this.eventInfo.geoPoint) {
        this.$toast.error(this.$t('alert_select_address'));
        return;
      }
      if (!this.eventInfo.genres.length) {
        this.$toast.error(this.$t('alert_select_genre'));
        return;
      }
      if (!this.eventInfo.description) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('description')]));
        return;
      }
      if (this.hasTicket && !this.onlyVisibleToMe) {
        if (!this.ticketList.length) {
          this.tabIndex = 0;
          this.$toast.error(this.$t('alert_add_event_tickets'));
          return;
        }
        this.eventInfo.ticketList = this.ticketList;
        this.eventInfo.promoList = this.promoList;
      }
      this.eventInfo.userId = this.$store.state.myId;
      this.eventInfo.hideAddress = !this.visibleAddress;
      this.eventInfo.onlyVisibleToMe = this.onlyVisibleToMe;
      this.eventInfo.displayCountDown = this.displayCountDown;
      if (this.cropResult && !this.eventInfo.photoUrl) {
        this.uploadPhotoTask();
        return;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEventInfo');
      func(JSON.stringify(this.eventInfo)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          if (this.eventInfo.eventId) {
            this.$toast.success(this.$t('alert_updated_successfully'));
          } else {
            this.$toast.success(this.$t('alert_added_successfully'));
            this.$router.push(`/my_events`);
          }
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>