<template>
  <div :class="`${isMobile ? 'h-100' : 'row w-100 h-100'}`">
    <div class="col-sm left-div" v-if="!isMobile" />
    <div :class="`${isMobile ? 'mt-10 mx-2' : 'col-sm d-flex flex-column justify-content-center'}`" v-if="userInfo">
      <div data-app class="m-5 p-5 box-white">
        <div class="center">
          <span class="font-25 font-bold">{{ $t('register') }}</span>
        </div>
        <div class="mt-5 browse-image">
          <img class="my-image rounded-circle" :src="`${photoUrl ? photoUrl : assets.profile}`" />
          <input
            type="file"
            accept="image/*"
            v-on:change="onFileChanged"
            placeholder="Photo"
          />
        </div>
        <v-dialog v-model="visibleCropDialog" width="800px">
          <v-card class="box-white">
            <Cropper
              class="m-5"
              ref="cropper"
              :style="`height: ${isMobile ? '400' : '600'}px;`"
              :src="selectedPhoto"
              :stencil-props="{
                aspectRatio: 1/1
              }"
            />
            <div class="d-flex justify-content-end">
              <v-btn class="button-normal" @click="cropDone()">
                <span class="px-5">OK</span>
              </v-btn>
              <v-btn class="ml-5 button-normal" @click="visibleCropDialog = false;">
                <span class="px-5">{{ $t('cancel') }}</span>
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="userInfo.email && !userInfo.email.endsWith(SUFFIX_SOCIAL_WITHOUT_EMAIL)">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('email') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="userInfo.email" disabled />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('phone') }}</span>
          <VuePhoneNumberInput
            class="w-75"
            default-country-code="DE"
            color="#F02121"
            error-color="#F02121"
            valid-color="#008FC7"
            :translations="phoneNumberInputTranslations"
            :disabled="!userInfo.email"
            v-model="phone"
            @update="onPhoneNumberUpdated" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('username') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="userInfo.userName" maxlength="40" oninput="this.value = this.value.replace(/[^0-9a-zA-Z\S]/g,'');" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('password') }}</span>
          <div :class="`${isMobile ? 'mt-1' : 'w-75'} d-flex justify-content-between vertical-center`">
            <input class="w-100 input-normal" v-model="password" :type="showPassword ? 'text' : 'password'" />
            <v-btn icon @click="showPassword = !showPassword">
              <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-btn>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('repeat_password') }}</span>
          <div :class="`${isMobile ? 'mt-1' : 'w-75'} d-flex justify-content-between vertical-center`">
            <input class="w-100 input-normal" v-model="passwordConfirm" :type="showPasswordConfirm ? 'text' : 'password'" />
            <v-btn icon @click="showPasswordConfirm = !showPasswordConfirm">
              <v-icon>{{ showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-btn>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('birthday') }}</span>
          <div class="w-75">
            <DatePicker v-model="datePicker" format="YYYY-MM-DD" type="date" />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('gender') }}</span>
          <b-form-radio-group
            class="w-75"
            buttons
            :stacked="isMobile"
            button-variant="outline-info"
            v-model="gender"
            :options="genderOptions"
          />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('which_music_do_you_like') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <div class="row">
              <div class="col-xl-4 col-6" v-for="(item, index) in genres" :key="(item, index)">
                <div :class="`my-1 genre-${item.name === genre ? 'selected' : 'normal'} vertical-center`" @click="genre = item.name">
                  <img :src="item.icon" :height="15" />
                  <span class="ml-5 font-12">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="userType === USER_ORGANIZER">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('description') }}</span>
            <textarea :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.description" rows="5" />
          </div>
        <div class="d-flex flex-column" v-if="userType === USER_ORGANIZER">
          <span class="my-5 font-label">{{ $t('confirm_organizer') }}</span>
          <b-form-checkbox :size="`${isMobile ? 'sm' : 'lg'}`" v-model="showOrganizer">{{ $t('confirm_want_sell_tickets_for_your_event') }}</b-form-checkbox>
        </div>
        <div class="d-flex flex-column" v-if="showOrganizer">
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('organizer_name') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.name" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('tax_code') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.taxCode" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('address') }}</span>
            <gmap-autocomplete :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" :value="organizerInfo.address" placeholder="" @place_changed="onPlaceChanged" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('phone') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.phone" />
          </div>
          <span class="mt-5 font-label">{{ $t('desc_organizer_bank_account') }}</span>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('iban_number') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="iban" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('account_holder_name') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.cardName" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="isTurkeyIBAN">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">Swift Code</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.swiftCode" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-else>
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('bic') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.cardBIC" />
          </div>
        </div>
        <div class="mt-5 d-flex justify-content-end">
          <v-btn class="button-normal" @click="registerTask()">
            <span class="px-10">{{ $t('register') }}</span>
          </v-btn>
        </div>
      </div>
      <TermsComponent></TermsComponent>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Reducer from 'image-blob-reduce';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { functions } from '../../../main';
import { getLocalStorageInfo, getCurDateString, getDateStringFromDate, isValidName, showLoading, showFunctionError } from '../../../functions';

import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

import profile from '@/assets/images/profile.png';
import genre_hiphop_rap from '@/assets/images/genre_hiphop_rap.png';
import genre_rock from '@/assets/images/genre_rock.png';
import genre_pop from '@/assets/images/genre_pop.png';
import genre_electro from '@/assets/images/genre_electro.png';
import genre_dj_dance from '@/assets/images/genre_dj_dance.png';

const IBAN = require('iban');

export default {
  name: 'Register',
  components: {
    DatePicker,
    Cropper,
    VuePhoneNumberInput,
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  watch: {
    iban() {
      if (this.organizerInfo) {
        this.organizerInfo.cardNumber = this.iban;
        if (IBAN.isValid(this.iban)) {
          this.getBICTask();
        }
      }
    }
  },
  data() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    return {
      phoneNumberInputTranslations: {
        countrySelectorLabel: this.$t('country_code'),
        phoneNumberLabel: this.$t('phone'),
        example: this.$t('example')
      },
      assets: {
        profile
      },
      genres: [
        {
          icon: genre_hiphop_rap,
          name: 'HipHop/Rap'
        },
        {
          icon: genre_rock,
          name: 'Rock'
        },
        {
          icon: genre_pop,
          name: 'Pop'
        },
        {
          icon: genre_electro,
          name: 'Electro'
        },
        {
          icon: genre_dj_dance,
          name: 'DJ/Dance'
        },
      ],
      userInfo: getLocalStorageInfo('eventboxRegisterUser'),
      userType: 0,
      phone: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      selectedPhoto: '',
      cropResult: null,
      photoUrl: '',      
      visibleCropDialog: false,
      showCrop: false,
      datePicker: date,
      genderOptions: [
        { text: this.$t('undefined'), value: 0 },
        { text: this.$t('male'), value: 1 },
        { text: this.$t('female'), value: 2 }
      ],
      gender: 0,
      genre: '',
      showOrganizer: false,
      isTurkeyIBAN: false,
      iban: '',
      organizerInfo: {
        name: '',
        address: '',
        phone: '',
        description: '',
        taxCode: '',
        cardName: '',
        cardNumber: '',
        cardBIC: '',
        swiftCode: ''
      }
    };
  },
  mounted() {
    if (!this.userInfo || (!this.userInfo.email && !this.userInfo.phone)) {
      this.$toast.error(this.$t('alert_unexpected_error'));
      this.$router.push(`login`);
      return;
    }
    if (this.userInfo && this.userInfo.photoUrl) {
      this.photoUrl = this.userInfo.photoUrl;
    }
    if (this.userInfo && this.userInfo.phone) {
      this.phone = this.userInfo.phone;
    }
    this.userType = parseInt(this.userInfo.userType);
  },
  methods: {
    onFileChanged(event) {
      if (event.target.files && event.target.files.length > 0) {
        const params = {
          max: 1300,
          unsharpAmount: 80,
          unsharpRadius: 0.6,
          unsharpThreshold: 2,
        };
        Reducer().toBlob(event.target.files[0], params).then(blob => {
          this.selectedPhoto = URL.createObjectURL(blob);
          this.visibleCropDialog = true;
        });
      }
    },
    cropDone() {
      this.visibleCropDialog = false
      const result = this.$refs.cropper.getResult();
      if (result) {
        const cropResult = result.canvas.toDataURL('image/jpeg');
        if (cropResult) {
          this.photoUrl = cropResult;
          this.cropResult = cropResult;
        }
      }
    },
    onPlaceChanged(placeData) {
      if (placeData.formatted_address) {
        this.organizerInfo.address = placeData.formatted_address;
      }
    },
    onPhoneNumberUpdated(data) {
      if (data.isValid && data.formattedNumber) {
        this.userInfo.phone = data.formattedNumber;
      } else {
        this.userInfo.phone = '';
      }
    },
    getBICTask() {
      axios.get(`https://openiban.com/validate/${this.iban}?getBIC=true`).then(response => {
        const bankData = response.data.bankData;
        if (bankData) {
          if (bankData.name) {
            this.organizerInfo.cardName = bankData.name;
          }
          if (bankData && bankData.bic) {
            this.organizerInfo.cardBIC = bankData.bic;
          }
        }
        this.isTurkeyIBAN = this.organizerInfo.cardNumber.startsWith('TR');
      });
    },
    uploadPhotoTask() {
      const metadata = { contentType: 'image/jpeg' };
      const storage = getStorage();
      const storageRef = ref(storage, `UserPhotos/${getCurDateString('YYYY-MM-DD')}/${getCurDateString()}_${Date.now()}.jpg`);
      const loader = showLoading(this, 1);
      uploadString(storageRef, this.cropResult, 'data_url', metadata).then(snapshot => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
          loader.hide();
          this.photoUrl = downloadURL;
          this.cropResult = '';
          this.registerTask();
        });
      }).catch(error => {
        loader.hide();
        this.$toast.error(this.$t('alert_unexpected_error'));
      });
    },
    registerTask() {
      if (!this.cropResult && !this.photoUrl) {
        this.$toast.error(this.$t('alert_select_photo'));
        return;
      }
      if (this.userInfo.userName.length < 4) {
        this.$toast.error(this.$t('alert_min_characters', [4, this.$t('username')]));
        return;
      }
      if (!isValidName(this.userInfo.userName)) {
        this.$toast.error(this.$t('alert_special_character_not_allowed'));
        return;
      }
      if (this.password.length < 6) {
        this.$toast.error(this.$t('alert_min_characters', [6, this.$t('password')]));
        return;
      }
      if (this.password !== this.passwordConfirm) {
        this.$toast.error(this.$t('alert_passwords_not_match'));
        return;
      }
      if (!this.genre) {
        this.$toast.error(this.$t('which_music_do_you_like'));
        return;
      }
      if (this.cropResult && !this.photoUrl.startsWith('https://firebasestorage.googleapis.com/v0/b/eventbox-c7c8f.appspot.com/o/UserPhotos')) {
        this.uploadPhotoTask();
        return;
      }
      const birthday = this.datePicker ? getDateStringFromDate(this.datePicker, 'YYYY-MM-DD') : '';
      const md5 = require('md5');
      const params = {
        userName: this.userInfo.userName,
        photoUrl: this.photoUrl,
        email: this.userInfo.email ? this.userInfo.email : '',
        phone: this.userInfo.phone ? this.userInfo.phone : '',
        password: md5(this.password),
        gender: this.gender,
        birthday: birthday,
        genre: this.genre,
        userType: this.userType
      };
      if (this.userType === this.USER_ORGANIZER) {
        if (this.organizerInfo.cardNumber) {
          if (!IBAN.isValid(this.organizerInfo.cardNumber) || !this.organizerInfo.cardName || !this.organizerInfo.cardBIC) {
            this.$toast.error(this.$t('alert_invalid_iban_number'));
            return;
          }
        }
        params['organizerInfo'] = this.organizerInfo;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        let myInfo;
        try {
          myInfo = JSON.parse(response.data);
        } catch(exception) {
          myInfo = null;
        }
        if (myInfo && myInfo.userId) {
          this.$store.commit('set', ['myId', myInfo.userId]);
          this.$store.commit('set', ['myInfo', myInfo]);
          localStorage.setItem('eventboxMyId', myInfo.userId);
          localStorage.setItem('eventboxMyInfo', JSON.stringify(myInfo));
          this.updateUserTask(myInfo.userId);
        } else if (response.data === this.RESULT_USER_UNAPPROVED) {
          window.alert(this.$t('alert_unapproved_organizer'));
          this.$router.push('/');
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    updateUserTask(userId) {
      const params = {
        userId: userId,
        setStatus: true,
        deviceType: `Web : ${navigator.userAgent}`
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'updateUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        this.$toast.success(this.$t('alert_success'));
        this.$router.push('/');
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>